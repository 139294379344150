global.App.FormViewBehavior = global.App.utils.merge({}, global.App.ElementBase, {
  properties: {
    item: {
      type: Object,
      notify: true,
    },
    createOtherItem: {
      type: Boolean,
      notify: true,
      value: true,
    },
  },

  _submit() {
    this.$.form.do();
  },

  _closeSideView() {
    // this.issues = {};
    const form = document.querySelector('#form');
    form.issues = {};
    app.$.state.goTo(this._entity);
  },
});
