global.debug = require('debug')('etaxi:debug');

require('./common/polyfill');

const raven = require('raven-js');

const CONFIG = require('./common/config');

raven
  .config(CONFIG.SENTRY_DSN, {
    release: CONFIG.APP_VERSION,
  })
  .install();

const App = (global.App = {});

App.moment = require('moment');

App.polyline = require('@mapbox/polyline');

App.throttle = require('lodash/throttle');

App.debounce = require('lodash/debounce');

App.CONFIG = require('./common/config');
App.CONST = require('./common/const');
App.EVENT = require('./common/event');
App.INTL = require('./common/intl');
App.LANG = require('./common/lang');
App.HELPER = require('./common/helper');

App.fetch = require('./common/fetch');

App.socketio = null;

App.utils = require('./common/utils');
App.DistanceUtils = require('./common/distance');
App.EstimateUtils = require('./common/estimate');

require('./marker-cache.js');

global.global = global;

global.Polymer = {
  // dom: 'shadow',
  // lazyRegister: true,
};

global.leafletMap = {};

require('../x-app.html');
