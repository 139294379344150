Polymer({
  is: 'portal-out',
});

Polymer({
  is: 'portal-in',

  properties: {
    out: {
      type: Object,
      notify: true,
    },
    for: {
      type: String,
    },
  },

  attached() {
    this.out = document.querySelector(`portal-out${this.for ? `#${this.for}` : ''}`);

    if (this.out) {
      // Polymer.dom(this).observeNodes(function(info) {
      while (this.out.firstChild) {
        this.out.removeChild(this.out.firstChild);
      }
      this.getEffectiveChildren().forEach((element, index) => {
        debug('portals', index, element);
        this.out.appendChild(element);
        Polymer.dom(this).removeChild(element);
      });
      // });
    }
  },

  detached() {
    if (this.out) {
      const { out } = this;
      const children = Array.prototype.slice.call(this.out.children);
      children.forEach((child) => {
        out.removeChild(child);
      });
    }
  },
});
