Polymer({
  is: 'email-verification-view',

  behaviors: [global.App.ElementBase],

  properties: {
    user: {
      type: Object,
      value() {
        return app.user;
      },
    },
    tokenSent: {
      type: Boolean,
      notify: true,
      value: false,
    },
    selectedState: {
      type: Boolean,
      notify: true,
      value() {
        return app.state.query.success === 'true' || (app.user && app.user.emailVerified)
          ? 'token-valid'
          : 'token-invalid';
      },
    },
  },

  _askForToken() {
    this.set('selectedState', 'token-resend');
    const body = this.$$('#form').serialize();
    global.App.fetch('/auth/email', {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.fire('success', response);
        this.issues = {};

        // var user = Object.assign(app.user, {
        //   email: this.$$("#emailInput").value
        // });
        // this.set('user', user);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _goToLogin() {
    app.$.state.goTo('login');
  },

  _goToMap() {
    app.$.state.goTo('home');
  },
});
