Polymer({
  is: 'hidden-input',

  behaviors: [Polymer.IronFormElementBehavior],

  properties: {
    value: {
      notify: true,
      type: Object,
    },
  },
});
