global.App.PaginateBehavior = global.App.utils.merge(
  {},
  {
    properties: {
      paginate: {
        type: Object,
        notify: true,
        value() {
          return { ...global.App.CONST.PAGINATE_INITIAL };
        },
      },
      query: {
        type: String,
        notify: true,
        value: null,
      },
      path: {
        type: String,
        notify: true,
      },
    },

    // PAGINATE_UTILS
    _goNextPage() {
      const page = this.paginate.page + 1;
      this.set('paginate.page', page);
      this._fetchPaginatedItems(this.path, {
        paginate: this.paginate,
        q: this.query || '',
      });
    },

    _goFirstPage() {
      this.set('paginate.page', 1);
      this._fetchPaginatedItems(this.path, {
        paginate: this.paginate,
        q: this.query || '',
      });
    },

    _goLastPage() {
      const page = this.paginate.pages;
      this.set('paginate.page', page);
      this._fetchPaginatedItems(this.path, {
        paginate: this.paginate,
        q: this.query || '',
      });
    },

    _goPreviousePage() {
      const page = this.paginate.page - 1;
      this.set('paginate.page', page);
      this._fetchPaginatedItems(this.path, {
        paginate: this.paginate,
        q: this.query || '',
      });
    },

    _pageSizeChanged() {
      this._fetchPaginatedItems(this.path, {
        paginate: this.paginate,
        q: this.query || '',
      });
    },

    _computedPaginateParams(options) {
      if (!options) {
        return '';
      }

      options.paginate = { ...global.App.CONST.PAGINATE_INITIAL, ...options.paginate };

      options.filterBy = options.filterBy || this.filterByValue || undefined;

      if (options.filterBy === undefined) {
        delete options.filterBy;
      }

      if (options.q === undefined) {
        delete options.q;
      }

      const params = { ...options };
      return params;
    },

    _fetchPaginatedItems(path, options) {
      if (!path) {
        return;
      }

      this.set('path', path);

      global.App.fetch(path, {
        query: this._computedPaginateParams(options),
      })
        .then((response) => {
          this._fetchSuccessHandler(response);
          this._loaded();

          if (!response.meta) {
            return;
          }

          const paginate = response.meta;
          paginate.pages = Math.ceil(paginate.count / paginate.pageSize);
          this.set('paginate', paginate);
        })
        .catch((error) => {
          this._fetchFailureHandler(error);
        });
    },

    _paginate(response) {
      if (response.page) {
        const { page } = response;
        this.set('page', page);
      }

      if (response.pageSize) {
        const { pageSize } = response;
        this.set('pageSize', pageSize);
      }

      this._loadPaginatedItems();
    },

    _computedFetchPaginatedParams() {
      const options = {
        paginate: {
          page: this.page,
          pageSize: this.pageSize,
        },
      };

      if (this.query) {
        options.q = this.query;
      } else {
        delete options.q;
      }

      return options;
    },
  },
);
