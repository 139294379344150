Polymer({
  is: 'app-event',

  properties: {
    on: {
      type: String,
    },
    do: {
      type: String,
    },
  },

  attached() {
    let parent = this.parentElement;
    if (!parent[this.do] && app[this.do]) {
      parent = app;
    }
    if (parent[this.do]) {
      this._handler = parent[this.do].bind(parent);
      global.App.EVENT.on(this.on, this._handler);
    }
  },

  detached() {
    if (this._handler) {
      global.App.EVENT.off(this.on, this._handler);
      this._handler = null;
    }
  },
});
