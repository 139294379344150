Polymer({
  is: 'signup-form',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
      value() {
        return {};
      },
    },
  },

  do() {
    const body = this.$$('#form').serialize();
    global.App.fetch(`/auth/signup?${global.App.CONFIG.AUTH_QUERY}`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.fire('success', response);
        this.issues = {};
      })
      .catch(this._fetchFailureHandler.bind(this));
  },

  _fetchFailureHandler(error) {
    this.issues = error.issues;

    if (error.code === 'SignupFailureUsedCredentials') {
      if (error.issues.user.phone) {
        app.showToast(error.message, {
          actionLabel: 'Récupérer compte',
          action() {
            app.$.state.goTo('account.reset-request');
          },
        });
      } else {
        app.showToast(error.message, {
          actionLabel: 'Réinitialiser mot de passe',
          action() {
            app.$.state.goTo('password.reset-request');
          },
        });
      }
    } else {
      app.showToast(error.message);
    }
  },
});
