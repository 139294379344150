Polymer({
  is: 'account-reset-request-view',

  behaviors: [global.App.ElementBase],

  _submit() {
    const body = this.$$('#form').serialize();

    global.App.fetch(`/auth/reset/account/request?${global.App.CONFIG.AUTH_QUERY}`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        app.$.state.goTo('account.reset');
        this.issues = {};
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
