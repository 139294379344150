Polymer({
  is: 'profile-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
    },
  },

  _computePhone(item) {
    return item.phone ? item.phone : item.user.phone;
  },
});
