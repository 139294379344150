Polymer({
  is: 'booking-detail-view',

  _entity: 'booking',

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    selectedState: {
      type: String,
      value: 'order',
    },
  },
});
