const debug = require('debug')(__filename);

const CONST = require('./const');
const INTL = require('./intl');
const LANG = require('./lang');

const EstimateUtils = require('./estimate');

const HELPER = {};

HELPER.$CONST = CONST;

HELPER.$INTL = INTL;

function $json(value) {
  try {
    const cache = [];
    return JSON.stringify(
      value,
      (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return undefined;
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      },
      2,
    );
  } catch (e) {
    return '(serialization failed)';
  }
}
HELPER.$json = $json;

function $_(value, keys, defaultValue) {
  if (keys) {
    keys = keys.split('.');
    while (value && keys.length) {
      const key = keys.shift();
      if (key) {
        value = value[key];
      }
    }
  }
  return value !== undefined ? value : defaultValue || '';
}
HELPER.$_ = $_;

Object.keys(INTL).forEach((fn) => {
  if (typeof INTL[fn] !== 'function') {
    return;
  }
  HELPER[`$${fn}`] = function (value) {
    if (value === undefined) {
      return '';
    }
    return INTL[fn].apply(null, arguments);
  };
});

Object.keys(INTL.CURRENT).forEach((fn) => {
  if (typeof INTL.CURRENT[fn] !== 'function') {
    return;
  }
  HELPER[`$${fn}`] = function (value) {
    if (value === undefined) {
      return '';
    }
    return INTL.CURRENT[fn].apply(null, arguments);
  };
});

function $secure(value, secure) {
  return secure ? Array(value.length + 1 || 4).join('•') : value;
}
HELPER.$secure = $secure;

const UNNAMED_ROAD_REGEXP = /Unnamed Road(, )?/;

function $address(value, alternative) {
  if (!value) {
    return alternative || '';
  }
  value = value.replace(CONST.ADDRESS_CLEANUP_REGEXP, '');
  value = value.replace(UNNAMED_ROAD_REGEXP, '');
  return value;
}
HELPER.$address = $address;

function $addressFromObject(address, alternative) {
  if (!address || (address && !address.zone && !address.fullAddress)) {
    return alternative || '';
  }

  const zone = $address(address.zone || '');
  const fullAddress = $address((address.fullAddress || '').replace(`, ${zone}`, ''));

  if (!zone) {
    return fullAddress;
  }
  if (!fullAddress) {
    return zone;
  }
  return `${fullAddress} \u2022 ${zone}`;
}
HELPER.$addressFromObject = $addressFromObject;

function $taxi_registration(value) {
  if (!value) {
    return '';
  }
  return INTL.taxi_registration(value);
}
HELPER.$taxi_registration = $taxi_registration;

function $metricsFromRoute(route, startedAt) {
  if (!route) {
    return '? km • ? minutes';
  }
  const estimate = EstimateUtils.calculateRouteEstimate(route, startedAt);
  return `${estimate.distanceFullText} • ${estimate.durationFullText}`;
}
HELPER.$metricsFromRoute = $metricsFromRoute;

function $estimateFromRoute(route, startedAt) {
  return EstimateUtils.calculateRouteEstimate(route, startedAt);
}
HELPER.$estimateFromRoute = $estimateFromRoute;

function $estimateFromOrder(order) {
  return EstimateUtils.calculateOrderEstimate(order);
}
HELPER.$estimateFromOrder = $estimateFromOrder;

function $orderStateSince(order, state) {
  return !!order && CONST.ORDER_IMMEDIATE_STATE.indexOf(order.state) >= CONST.ORDER_IMMEDIATE_STATE.indexOf(state);
}
HELPER.$orderStateSince = $orderStateSince;

function $order_subtype(value) {
  return LANG.CURRENT.LABEL.ORDER_SUBTYPE[value] || '?';
}
HELPER.$order_subtype = $order_subtype;

function $order_billingMode(value) {
  return LANG.CURRENT.LABEL.ORDER_BILLING_MODE[value] || '?';
}
HELPER.$order_billingMode = $order_billingMode;

function $order_paymentMode(value) {
  return LANG.CURRENT.LABEL.ORDER_PAYMENT_MODE[value] || '?';
}
HELPER.$order_paymentMode = $order_paymentMode;

function $order_state(value) {
  return LANG.CURRENT.LABEL.ORDER_STATE[value] || '?';
}
HELPER.$order_state = $order_state;

Object.assign(HELPER, require('./helper-extra'));

module.exports = Object.seal(HELPER);
