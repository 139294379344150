Polymer({
  is: 'etaxi-guide',

  behaviors: [global.App.ElementBase],

  properties: {
    requestPositionFixed: {
      type: Boolean,
      notify: true,
      value: false,
    },
    selectedState: {
      type: String,
      notify: true,
      value() {
        return 'select-requested-position';
      },
    },
  },

  attached() {
    global.App.EVENT.on('selecting-destination-position', (response) => {
      this.set('selectedState', 'order-request');
    });

    global.App.EVENT.on('order-cancelling-success', (response) => {
      this.set('selectedState', 'select-requested-position');
    });

    global.App.EVENT.on('select-request-position-success', (response) => {
      if (!response) {
        return;
      }

      this._setNextStep();
    });
  },

  _setNextStep() {
    this.set('selectedState', 'select-destination-position');
  },
});
