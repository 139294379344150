Polymer({
  is: 'order-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    orderStates: {
      type: Object,
      readOnly: true,
      value: global.App.CONST.ORDER_STATE_SET,
    },
  },
});
