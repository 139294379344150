Polymer({
  is: 'detail-element',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      value: null,
      notify: true,
    },
    label: {
      type: String,
      value: '',
    },
  },
});
