Polymer({
  is: 'order-overview',

  _entity: 'overview',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
    loaded: {
      type: Boolean,
      value: false,
    },
  },

  _loaded() {
    this.loaded = true;
  },

  observers: ['_loadFetchItems(dateRange.*)'],

  _value(value, keys, defaultValue) {
    if (keys) {
      keys = keys.split('.');
      while (value && keys.length) {
        const key = keys.shift();
        if (key) {
          value = value[key];
        }
      }
    }
    return value !== undefined ? value : defaultValue || '';
  },

  _loadFetchItems(dateRange) {
    dateRange = Object.assign(this.dateRange, dateRange.base);

    global.App.fetch('/client/order/overview', {
      query: dateRange,
    })
      .then((response) => {
        const item = response.overview;
        this.set('item', item);
        this._loaded();
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
        this._loaded();
      });
  },

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }

      this.set('dateRange', response);
    });
  },
});
