Polymer({
  is: 'reset-email-view',

  behaviors: [global.App.ElementBase],

  properties: {
    passcode: {
      type: String,
      notify: true,
    },
  },

  _submit() {
    const body = this.$$('#form').serialize();
    global.App.fetch(`/auth/reset/account/${this.passcode}?${global.App.CONFIG.AUTH_QUERY}`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.fire('reset-email-success', response);
        this.issues = {};
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
