Polymer({
  is: 'profile-card',

  behaviors: [global.App.ElementBase, global.App.PaperDropdownContained],

  properties: {
    user: {
      type: Object,
    },
  },

  goProfile() {
    this.closeContainingDropdown();
    app.$.state.goTo('profile');
  },

  doLogout() {
    this.closeContainingDropdown();
    app.logout();
  },
});
