<dom-module id="leaflet-geojson">
  <style>
    :host {
      display: none;
    }
  </style>
  <template> </template>
  <script>
    Polymer({
      is: 'leaflet-geojson',
    });
  </script>
</dom-module>
