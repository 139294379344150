
require('../shared/date-range-selection.html');

require('./order-overview.html');

const RegisterHtmlTemplate = require('polymer-webpack-loader/register-html-template');

RegisterHtmlTemplate.register("<dom-module id=history-list-view> <template> <style include=shared-styles></style> <style>:host{display:block;@apply (--layout);@apply (--layout-fit);@apply (--layout-vertical);}.-divider:not(:last-child){box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);border-bottom:.5px solid var(--divider-color)}</style> <detail-element class=flex> <paper-toolbar> <paper-tabs noink=\"\" attr-for-selected=data-state selected={{selectedState}} class=\"flex bottom self-start\"> <paper-tab data-state=overview>Aperçu</paper-tab> <paper-tab data-state=order>Immédiates</paper-tab> <paper-tab data-state=booking>Réservations</paper-tab> </paper-tabs> </paper-toolbar> <iron-pages attr-for-selected=data-state selected=[[selectedState]] class=-margin-2x> <section data-state=overview> <template is=dom-if restamp=\"\" if=\"{{ _is(selectedState, 'overview') }}\"> <order-overview></order-overview> </template> </section> <section data-state=order> <template is=dom-if restamp=\"\" if=\"{{ _is(selectedState, 'order') }}\"> <order-list-view></order-list-view> </template> </section> <section data-state=booking> <template is=dom-if restamp=\"\" if=\"{{ _is(selectedState, 'booking') }}\"> <booking-list-view></booking-list-view> </template> </section> </iron-pages> </detail-element> <div class=-divider></div> <date-range-selection class=-padding-1x></date-range-selection> </template> </dom-module>");

require('./history-list-view.js');
