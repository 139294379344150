const REVIEW_SCORE_EMOTICON_SET = {
  1: {
    icon: 'social:sentiment-very-dissatisfied',
  },
  2: {
    icon: 'social:sentiment-dissatisfied',
  },
  3: {
    icon: 'social:sentiment-neutral',
  },
  4: {
    icon: 'social:sentiment-satisfied',
  },
  5: {
    icon: 'social:sentiment-very-satisfied',
  },
};

Polymer({
  is: 'map-order-request-sm-view',

  _entity: 'client',

  behaviors: [global.App.ElementBase, global.App.RequestUtils],

  properties: {
    loaderState: {
      type: Boolean,
      value: false,
    },
    reviewScoreEmoticonSet: {
      type: Object,
      value: REVIEW_SCORE_EMOTICON_SET,
    },
  },

  attached() {
    global.App.EVENT.on(
      'select-request-position-success',
      (this._onSelectRequestPositionSuccess = (response) => {
        if (!response) {
          return;
        }

        this.set('fixedRequestAddress', true);

        this.$$('#selectRequestAddress').value = response.item.fullAddress;
        this.set('loaderState', false);
        this.$$('#selectDestinationAddress').disabled = false;
        this.$$('#selectRequestAddress').disabled = true;
      }),
    );

    global.App.EVENT.on(
      'selecting-destination-position',
      (this._onSelectingDestinationPosition = (response) => {
        this.$$('#selectDestinationAddress').value = response.fullAddress;
      }),
    );
  },

  detatched() {
    if (this._onSelectRequestPositionSuccess) {
      global.App.EVENT.off('select-request-position-success', this._onSelectRequestPositionSuccess);
    }
    if (this._onSelectingDestinationPosition) {
      global.App.EVENT.off('selecting-destination-position', this._onSelectingDestinationPosition);
    }
  },
});
