Polymer({
  is: 'pricing-view',

  behaviors: [global.App.ElementBase],

  properties: {
    selectedState: {
      type: String,
      value: 'order',
    },
  },
});
