Polymer({
  is: 'booking-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    orderStates: {
      type: Object,
      readOnly: true,
      value: global.App.CONST.BOOKING_STATE_SET,
    },
  },

  _goChangeDate() {
    this.$.changeDateDialog.open();
  },

  _goCancelOrder() {
    this.$.cancelOrderDialog.open();
  },

  _showOnMap() {
    global.App.EVENT.emit('order:show:map', this.item);
    app.$.state.goTo('home');
  },
});
