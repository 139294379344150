Polymer({
  is: 'count-up-timer',

  /** *
   * @private
   * @type String
   * @default '000000'
   */
  // time: '000000',

  /** *
   * @private
   * @default null
   */
  timer: null,

  properties: {
    /**
     * Starting minutes for the countup
     *
     * @attribute startFrom
     * @type Number
     */
    startFrom: {
      type: Number,
      value: null,
    },
    /**
     * start the timer
     *
     * @attribute isRunning
     * @type Boolean
     * @default false
     */
    isRunning: {
      type: Boolean,
    },
    time: {
      type: String,
      value: '000000',
    },
    /**
     * Auto start the timer
     *
     * @attribute auto
     * @type Boolean
     * @default false
     */
    auto: {
      type: Boolean,
      value: false,
    },
  },

  // observers: ['_start(auto)'],

  /**
   * The `_createTimer` method create a timer
   *
   * @method _createTimer
   * @private
   */
  _createTimer() {
    if (this.isRunning) {
      this.time = this.timer.add(1, 's').format('mm:ss');
      this.async(this._createTimer, 1000);
    }
  },

  /**
   * The `_createCountdown` method create a countdown
   *
   * @method _createCountdown
   * @private
   */
  _createCountdown() {
    if (this.isRunning) {
      if (
        String(this.time)
          .split(':')
          .join('') > 0
      ) {
        this.time = this.timer.subtract(1, 's').format('mm:ss');
        this.async(this._createCountdown, 1000);
      }
    }
  },

  /**
   * The `_start` method starts the timer
   *
   * @method _start
   * @private
   */
  _start() {
    if (!this.timer) {
      let now = new Date();
      now = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      now.setSeconds(this.startFrom || 0);

      const timerSettings = {
        seconds: now.getSeconds(),
        minutes: now.getMinutes(),
        hours: now.getHours(),
      };
      this.timer = global.App.moment()
        .hours(timerSettings.hours)
        .minutes(timerSettings.minutes)
        .seconds(timerSettings.seconds);
    }
    this.set('isRunning', true);

    if (this.startFrom) {
      this._createCountdown();
    } else {
      this._createTimer();
    }
  },

  _restart() {
    this._reset();
    if (this.startFrom) {
      this.time = this._computedTimer(this.startFrom);
      this._start();
    }
  },

  /**
   * The `_stop` stop the running timer
   *
   * @method _stop
   * @private
   */
  _stop() {
    this.set('isRunning', false);
  },

  /**
   * The `_reset` reset counter
   *
   * @method _reset
   * @private
   */
  _reset() {
    this.set('isRunning', false);
    this.time = this.startFrom ? this._computedTimer(this.startFrom) : '000000';
    this.timer = null;
  },

  ready() {
    this._restart();
  },

  _computedTimer(startFrom) {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    now.setSeconds(startFrom);

    const timerSettings = {
      seconds: now.getSeconds(),
      minutes: now.getMinutes(),
      hours: now.getHours(),
    };

    const timer = `00${timerSettings.hours}`.slice(-2)
      + `00${timerSettings.minutes}`.slice(-2)
      + `00${timerSettings.seconds}`.slice(-2);

    return timer;
  },
});
