global.App.DetailViewBehavior = global.App.utils.merge({}, global.App.ElementBase, {
  properties: {
    item: {
      type: Object,
      notify: true,
    },
  },

  // [GET] ITEMS using FETCH_API
  _fetchItems(path, query) {
    if (!path) {
      return;
    }

    if (this.loaded) {
      this.loaded = false;
    }

    global.App.fetch(path, {
      query: query || '',
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  attached() {
    // this._fetchItems('/admin/' + this._entity + '/' + this.item.id);
    if (this.$.ajax) {
      this.$.ajax.generateRequest();
    }
  },

  _socketItemUpdated(item, properties) {
    if (Array.isArray(properties)) {
      properties.forEach(function (property) {
        this.set(`item.${property}`, item.value[property]);
      });
    } else {
      const property = properties;
      this.set(`item.${property}`, item.value[property]);
    }
  },

  _submit() {
    this.$.form.do();
  },

  _goEdit() {
    app.$.cache.set(this.item.id, this.item);
    app.$.state.goTo(`${this._entity}.edit`, this.item.id);
  },

  _goDelete() {
    this.$.deleteDialog.open();
  },

  _doDelete() {
    this.$.deleteAjax.generateRequest();
  },

  _successDeleteHandler(event, detail) {
    global.App.EVENT.emit('item-deleted', detail.response.deleted);
    app.$.state.goTo(this._entity);
  },

  _closeSideView() {
    app.$.state.goTo(this._entity);
  },
});
