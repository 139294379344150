global.App.ListViewBase = global.App.utils.merge({}, global.App.ElementBase, {
  _dataField: 'items',

  properties: {
    items: {
      type: Array,
      notify: true,
    },
    selectedItem: {
      type: Object,
      notify: true,
    },
    orderStates: {
      type: Object,
      value: global.App.CONST.ORDER_STATE_SET,
    },
  },

  // [GET] ITEMS using FETCH_API
  _fetchItems(path, query) {
    if (!path) {
      return;
    }
    global.App.fetch(path, {
      query: query || '',
    })
      .then((response) => {
        // @TODO thinking about adding a callback
        // if (callback) {
        //       callback(null, response);
        //     } else {
        //       this._fetchSuccessHandler(response);
        //     }
        this._fetchSuccessHandler(response);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  // [GET] ITEMS using SOCKET
  _getItems(options, callback) {
    if (!options || typeof options === 'function') {
      return;
    }

    if (this.loaded) {
      this.set('loaded', false);
    }

    const _path = options.path;
    const _entity = options.entity || this._entity;
    const _query = options.query || '';

    global.App.socketio.emit(_path, _query, (error, result) => {
      this._loaded();
      if (error) {
        if (callback) {
          callback(error);
        } else {
          app.showToast(error.message);
        }
        return;
      }

      if (callback) {
        callback(null, result[_entity]);
      } else {
        const _item = Array.isArray(result[_entity]) ? 'items' : 'item';
        this.set(_item, result[_entity]);
      }
    });
  },

  _goDetails(event, detail) {
    const { item } = detail;
    app.$.cache.set(item.id, item);
    app.$.state.goTo(`${this._entity}.detail`, item.id);
  },
});
