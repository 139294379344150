const DELAY = 30 * 24 * 3600 * 1000; // a month-ago

Polymer({
  is: 'date-range-selection',

  behaviors: [global.App.TemplateHelpers],

  properties: {
    locale: {
      type: String,
      readOnly: true,
      value: 'fr',
    },
    _from: {
      type: Date,
      notify: true,
      value() {
        let now = new Date();
        now = new Date(now.getTime() - DELAY);
        return now.toISOString().split('T')[0];
      },
    },
    _until: {
      type: Date,
      notify: true,
      value() {
        const now = new Date();
        return now.toISOString().split('T')[0];
      },
    },
  },

  observers: ['_dateChange(_from,_until)'],

  _validateDate(_from, _until) {
    if (_from > _until) {
      app.showToast('Date invalide.');
      _from = new Date(new Date(_until).getTime() - DELAY);
      _from = _from.toISOString().split('T')[0];
      this.set('_from', _from);
    }
    return _from;
  },

  _dateChange(_from, _until) {
    _from = this._validateDate(_from, _until);

    _from = global.App.moment(_from).format('YYYY-MM-DD');
    _until = global.App.moment(_until).format('YYYY-MM-DD');

    this.fire('date-change', {
      from: _from,
      until: _until,
    });

    global.App.EVENT.emit('date:change', {
      from: _from,
      until: _until,
    });
  },

  attached() {
    this._dateChange(this._from, this._until);
  },

  _openFromDatepicker() {
    this.$$('#fromDatepicker').open();
  },

  _openUntilDatepicker() {
    this.$$('#untilDatepicker').open();
  },
});
