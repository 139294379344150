Polymer({
  is: 'booking-upcoming-list-view',

  _entity: 'booking',

  behaviors: [
    global.App.ListViewBase,
    // global.App.PaginateBehavior,
  ],

  attached() {
    // this._fetchPaginatedItems('/client/booking/upcoming', {
    //   sortedBy: 'initiatedAt DESC',
    //   paginate: this.paginate,
    // });
    this._loadFetchedItems();
    global.App.EVENT.on('view:refresh', () => {
      this._loadFetchedItems();
    });
  },

  _loadFetchedItems() {
    this._fetchItems('/client/booking/upcoming', {
      sortedBy: 'requestedAt ASC',
    });
  },

  _goToMap() {
    app.$.state.goTo('home');
  },
});
