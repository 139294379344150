global.App.TemplateExtraHelpers = {
  showHowTo() {
    global.App.EVENT.emit('action:open-howto');
  },

  showPricing() {
    global.App.EVENT.emit('action:open-tarification');
  },

  chatWithOperator() {
    try {
      global.Tawk_API.maximize();
    } catch (e) {
      window.open('https://go.etaxi.tn/chat');
    }
  },

  callOperator() {
    window.location.href = `tel:${global.App.CONST.PHONE_NUMBER}`;
  },
};
