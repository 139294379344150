
require('../iron-flex-layout/iron-flex-layout.html');

require('../paper-styles/color.html');

require('../paper-styles/default-theme.html');

const RegisterHtmlTemplate = require('polymer-webpack-loader/register-html-template');

RegisterHtmlTemplate.register("<dom-module id=paper-menu-shared-styles> <template> <style>.selectable-content>::content>.iron-selected{font-weight:700;@apply(--paper-menu-selected-item);}.selectable-content>::content>[disabled]{color:var(--paper-menu-disabled-color,--disabled-text-color)}.selectable-content>::content>:focus{position:relative;outline:0;@apply(--paper-menu-focused-item);}.selectable-content>::content>:focus:after{@apply(--layout-fit);background:currentColor;opacity:var(--dark-divider-opacity);content:'';pointer-events:none;@apply(--paper-menu-focused-item-after);}.selectable-content>::content>[colored]:focus:after{opacity:.26}</style> </template> </dom-module>");
