Polymer({
  is: 'order-detail-view',

  _entity: 'order',

  properties: {
    selectedState: {
      type: String,
      value: 'order',
    },
  },
});
