const debug = require('debug')('etaxi:event');

const EventEmitter2 = require('eventemitter2');

function create(ns) {
  const EVENT = new EventEmitter2({
    maxListeners: 1000,
  });

  EVENT.off = EVENT.removeAllListeners;

  EVENT.only = function (event, listener) {
    this.removeAllListeners(event);
    this.on(event, listener);
  };

  EVENT.onAny((event) => {
    debug(ns, event);
  });

  return EVENT;
}

const EVENT = create('global');

EVENT.create = create;

module.exports = EVENT;
