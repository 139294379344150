Polymer({
  is: 'map-order-request-view',

  _entity: 'client',

  behaviors: [global.App.ElementBase, global.App.RequestUtils],

  properties: {},

  _computedCover() {
    return global.App.EstimateUtils.isSurcharged() ? '-background-image-night' : '-background-image-day';
  },

  attached() {
    global.App.EVENT.on(
      'select-request-position-success',
      (this._onSelectRequestPositionSuccess = (response) => {
        this.set('fixedRequestAddress', true);
      }),
    );
  },

  detatched() {
    if (this._onSelectRequestPositionSuccess) {
      global.App.EVENT.off('select-request-position-success', this._onSelectRequestPositionSuccess);
    }
  },
});
