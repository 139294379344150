global.App.RequestUtils = {
  properties: {
    selectedState: {
      type: String,
      notify: true,
      value: 'select-requested-position',
    },
    order: {
      type: Object,
      notify: true,
    },
    orderNote: {
      type: String,
      notify: true,
    },
    cancelNote: {
      type: String,
      notify: true,
    },
    clientReviewScore: {
      type: String,
      notify: true,
      value: 4,
    },
    clientReviewNote: {
      type: String,
      notify: true,
    },
    isBooking: {
      type: Boolean,
      notify: true,
      value: false,
    },
    orderCancelReasonSet: {
      // to be removed
      type: Array,
      readOnly: true,
      value: global.App.CONST.ORDER_CANCEL_REASON_SET,
    },
    selectedCancelReason: {
      // to be removed
      type: Object,
      value: global.App.CONST.ORDER_CANCEL_REASON_SET[0],
    },
    fixedRequestAddress: {
      type: Boolean,
      value: false,
    },
  },

  observers: ['_computedOrderState(order.*)'],

  _currentOrderLoad() {
    this.order = this.order || null;
  },

  _computedOrderState(order) {
    order = order.base;

    if (!order) {
      this.set('selectedState', 'select-requested-position');
      return;
    }

    const selectedState = global.App.CONST.SELECTED_REQUESTED_ORDER_STATES[order.state].value;
    this.set('selectedState', selectedState);

    global.App.EVENT.emit('address-loaded', {
      item: {
        requestPosition: order.requestAddress.position,
        destinationPosition: order.destinationAddress.position,
        route: order.route,
      },
    });
  },

  ready(detail, event) {
    global.App.EVENT.on('order:show:map', (response) => {
      if (!response) {
      }
      // @TODO WRITE SOME CODE.
    });

    // CANCEL_REQUEST
    global.App.EVENT.on(':continue:cancel-request', (response) => {
      if (response && response.cancelledOrder) {
        this._cancelOrder(response.cancelledOrder);
        return;
      }

      if (this.$$('#orderCancelDialog') && this.$$('#orderCancelDialog').opened) {
        this.$$('#orderCancelDialog').close();
      }
    });

    // REQUEST_BOOKING_WORKFLOW
    global.App.EVENT.on(':continue:booking-request', (response) => {
      this.set('isBooking', true);

      if (response && response.requestedAt) {
        const order = { ...this.order, ...response };
        this.set('order', order);
        debug('requestedAt-continue-after', this.order.requestedAt, response.requestedAt);
        this._requestBooking();
        return;
      }

      if (this.$$('#bookingRequestDialog') && this.$$('#bookingRequestDialog').opened) {
        this.$$('#bookingRequestDialog').close();
      }
    });

    // LOAD_ORDER
    global.App.EVENT.on('order-loaded', (response) => {
      const order = response;
      if (!order.state) {
        order.state = global.App.CONST.ORDER_IMMEDIATE_STATE.INITIATED;
      }
      if (order.source === global.App.CONST.ORDER_SOURCE.CALL) {
        order.timeout = 600;
      }
      this.set('order', order);
      this.notifyPath('order.timeout');
    });

    // REFRESH-PROFILE-PHONE-VERIFICATON
    global.App.EVENT.on('view:refresh', (response) => {
      if (!response) {
        return;
      }
      if (this.$.verifyPhoneDialog && this.$.verifyPhoneDialog.opened) {
        this.$.verifyPhoneDialog.close();
      }
      if (this.isBooking) {
        this._requestBooking();
      } else {
        this._requestOrder();
      }
    });

    // REQUEST_ORDER_WORKFLOW
    global.App.socketio.on('client:order:pending', (response) => {
      const order = { ...this.order, ...response };
      if (order.source === global.App.CONST.ORDER_SOURCE.CALL) {
        order.timeout = 600;
      }
      // debug("attached-order-current-pending", result.order);
      this.set('order', order);
    });

    global.App.socketio.on('client:order:started', (response) => {
      const order = { ...this.order, ...response };
      this.set('order', order);
    });

    global.App.socketio.on('client:order:onhold', (response) => {
      const order = { ...this.order, ...response };
      this.set('order', order);
    });

    global.App.socketio.on('client:order:ongoing', (response) => {
      const order = { ...this.order, ...response };
      this.set('order', order);
    });

    global.App.socketio.on('client:order:finished', (response) => {
      const order = { ...this.order, ...response };
      this.set('order', order);
    });

    global.App.socketio.on('client:order:timedout', (response) => {
      const order = { ...this.order, ...response };
      this.set('order', order);

      if (this.$.orderCancelDialog.opened) {
        this.$.orderCancelDialog.close();
      }
    });

    global.App.socketio.on('client:order:cancelled', (response) => {
      const order = { ...this.order, ...response };
      this.set('order', order);
    });
  },

  _fixPosition() {
    return this.fixedRequestAddress ? this._fixDestinationPosition() : this._fixRequestPosition();
  },

  _fixRequestPosition() {
    if (this.loaderState !== undefined) {
      this.set('loaderState', true);
    }

    global.App.EVENT.emit('select-requested-position', 'requestPosition');
  },

  _fixDestinationPosition() {
    if (this.loaderState !== undefined) {
      this.set('loaderState', true);
    }
    global.App.EVENT.emit('select-destination-position', 'destinationPosition');
  },

  _goRequestBooking() {
    this.set('isBooking', true);
    this.$.bookingRequestDialog.open();
  },

  _requestBooking() {
    if (this.$.verifyPhoneDialog && this.$.verifyPhoneDialog.opened) {
      this.$.verifyPhoneDialog.close();
    }

    const order = {
      route: this.order.route,
      requestAddress: this.order.requestAddress,
      destinationAddress: this.order.destinationAddress,
      requestedAt: this.order.requestedAt,
    };

    global.App.socketio.emit(
      '/client/booking/request',
      {
        order: this.order,
      },
      (error, result) => {
        if (error) {
          if (error.code === 'PhoneNotVerified') {
            app.showToast(error.message, {
              actionLabel: 'Vérifier',
              action: () => {
                this.$.verifyPhoneDialog.open();
              },
            });
          } else {
            app.showToast(error.message);
          }
          return;
        }
        app.showToast('Votre réservation a bien été enregistrée!');

        if (this.$$('#bookingRequestDialog') && this.$$('#bookingRequestDialog').opened) {
          this.$$('#bookingRequestDialog').close();
        }

        this._clearRequest();
      },
    );
  },

  _requestOrder() {
    if (this.$.verifyPhoneDialog && this.$.verifyPhoneDialog.opened) {
      this.$.verifyPhoneDialog.close();
    }

    const order = {
      route: this.order.route,
      requestAddress: this.order.requestAddress,
      destinationAddress: this.order.destinationAddress,
    };

    if (this.orderNote) {
      order.note = this.orderNote;
    }

    global.App.socketio.emit(
      '/client/order/request',
      {
        order,
      },
      (error, result) => {
        if (error) {
          if (error.code === 'PhoneNotVerified') {
            app.showToast(error.message, {
              actionLabel: 'Vérifier',
              action: () => {
                this.$.verifyPhoneDialog.open();
              },
            });
          } else {
            app.showToast(error.message);
          }
          return;
        }

        const order = { ...this.order, ...result.order };

        if (order.source === global.App.CONST.ORDER_SOURCE.CALL) {
          order.timeout = 600;
        }

        this.set('order', order);
        this.notifyPath('order.timeout');
      },
    );
  },

  //* *** UTILS
  _computeOrderEstimate(order) {
    const { cost } = global.App.EstimateUtils.calculateOrderEstimate(order);
    return this.$currency(cost, '');
  },
  //* *** END_UTILS

  _goCancelOrder() {
    this.$.orderCancelDialog.open();
  },

  _cancelOrder(cancelledOrder) {
    if (!cancelledOrder) {
      return;
    }

    global.App.socketio.emit(
      '/client/order/:orderId/cancel',
      {
        orderId: this.order.id,
        cancelReason: cancelledOrder.cancelReason,
        cancelNote: cancelledOrder.cancelNote,
      },
      (error, result) => {
        if (error) {
          app.showToast(error.message);
          return;
        }
        if (this.$.orderCancelDialog.opened) {
          this.$.orderCancelDialog.close();
        }
        app.showToast('Votre course immédiate a bien été annulée!');

        if (this.$$('#orderCancelDialog') && this.$$('#orderCancelDialog').opened) {
          this.$$('#orderCancelDialog').close();
        }

        this._clearRequest();
      },
    );
  },

  _clearRequest() {
    this.set('selectedState', 'select-requested-position');

    // this.set('loaderState', true);

    if (this.order) {
      this.set('order', null);
    }

    if (this.clientReviewScore) {
      this.set('clientReviewScore', 2);
    }

    if (this.clientReviewNote) {
      this.set('clientReviewNote', null);
    }

    if (this.cancelledOrder) {
      this.set('cancelledOrder', null);
    }

    this.set('fixedRequestAddress', false);

    if (this.loaderState) {
      this.set('loaderState', false);
    }

    if (this.$$('#selectDestinationAddress') && !this.$$('#selectDestinationAddress').disabled) {
      this.$$('#selectDestinationAddress').disabled = true;
    }

    if (this.$$('#selectDestinationAddress') && !this.$$('#selectDestinationAddress').value) {
      this.$$('#selectDestinationAddress').value = '';
    }

    if (this.$$('#selectRequestAddress') && this.$$('#selectRequestAddress').disabled) {
      this.$$('#selectRequestAddress').disabled = false;
    }

    if (this.$$('#selectRequestAddress') && this.$$('#selectRequestAddress').value) {
      this.$$('#selectRequestAddress').value = '';
    }

    global.App.EVENT.emit('cancel-request-emmitted', 'orderCancelled');
  },

  _evaluateRequest(event, detail) {
    const review = {
      clientReviewScore: this.clientReviewScore,
      clientReviewNote: this.clientReviewNote,
    };

    global.App.socketio.emit(
      '/client/order/:orderId/review',
      {
        orderId: this.order.id,
        review,
      },
      (error, result) => {
        if (error) {
          app.showToast(error.message);
          return;
        }

        if (this.$.successReviewDialog) {
          this.$.successReviewDialog.open();
        }

        this._clearRequest();
      },
    );
  },

  _relaunchOrder(event, detail) {
    global.App.socketio.emit(
      '/client/order/:orderId/reinitiate',
      {
        orderId: this.order.id,
      },
      (error, result) => {
        if (error) {
          app.showToast(error.message);
          return;
        }

        const order = { ...this.order, ...result.order };
        this.set('order', order);
      },
    );
  },

  _relaunchCancelledOrder(event, detail) {
    this.set('selectedState', 'order-request');
  },

  _notifyAllPath(item, obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        this.notifyPath(`${item}.${key}`);
      }
    }
  },
};
