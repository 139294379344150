Polymer({
  is: 'list-element',

  behaviors: [global.App.ListViewBase],

  properties: {
    loaded: {
      type: Boolean,
      value: false,
    },
  },
});
