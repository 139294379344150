global.App.MapHelpers = {
  properties: {
    map: {
      type: Object,
      notify: true,
      value() {
        return null;
      },
    },
    position: {
      type: Object,
      notify: true,
      value: global.App.CONST.MAP_INITIAL_POSITION.slice(),
    },
    center: {
      type: Object,
      notify: true,
      value: global.App.CONST.MAP_INITIAL_POSITION.slice(),
    },
    zoom: {
      type: Number,
      notify: true,
      value: 12,
    },
    geolocationEnabled: {
      type: Boolean,
      value: false,
    },
    geolocationIcon: {
      type: String,
      value: 'device:location-searching',
    },
    route: {
      type: Object,
      notify: true,
    },
    search: {
      type: String,
      notify: true,
    },
    searchPlaceholder: {
      type: String,
      notify: true,
      // value: 'Départ',
      value: 'Recherche',
    },
    searchResults: {
      type: Object,
      notify: true,
    },
  },

  _mapReady() {
    // this._getLocation();
  },

  _getLocation() {
    this._getCurrentPosition()
      .then((position) => {
        position = position.coords;
        this._setCenter(position.latitude, position.longitude);
        this.set('zoom', 15);
      })
      .catch((error) => {
        if (!error) {
          return;
        }
        app.showToast('Localisation échouée. Penser à activer la location dans votre navigateur.');
      });
  },

  _openLocationHowTo() {
    this.locationDialog.open();
  },

  _getCurrentPosition() {
    // if (this._geolocationOngoing) {
    //   return Promise.reject();
    // }

    return new Promise((resolve, reject) => {
      this._geolocationOngoing = true;
      app.showActivity();
      navigator.geolocation.getCurrentPosition(
        (position) => {
          app.clearActivity();
          this.set('geolocationEnabled', true);
          this.set('geolocationIcon', 'maps:my-location');
          this._geolocationOngoing = false;
          resolve(position);
        },
        (error) => {
          app.clearActivity();
          app.showToast(error.message);
          if (error.code === error.PERMISSION_DENIED) {
            this.set('geolocationEnabled', false);
            this.set('geolocationIcon', 'device:location-disabled');
            this._geolocationOngoing = false;
          }
          reject(error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 30000,
        },
      );
    });
  },

  _locate() {
    this.$.map.clear();
    this.position = [33.7735788, 10.753318];
  },

  _zoomIn() {
    this.zoom = this.$.map.map.zoomIn();
  },

  _zoomOut() {
    this.zoom = this.$.map.map.zoomOut();
  },

  _marker_icon() {
    const path = this._url.apply(this, arguments);

    const result = {
      iconUrl: path,
    };

    const marker = global.App.MARKER_CACHE[path];

    if (marker) {
      // result.iconUrl = marker.path;
      result.iconAnchor = [marker.width / 2, marker.height];
    }

    return result;
  },

  _taxi_marker_icon(path, state, rotation) {
    // debug('_taxi_marker_icon', arguments);
    rotation = rotation || 0;
    if (rotation < 0) {
      rotation = 360 + rotation;
    }
    const marker = global.App.MARKER_CACHE[path.replace(':state', state)][rotation || 0];
    return {
      // iconUrl: path.replace(':state', state),
      iconUrl: marker.path,
      iconAnchor: [marker.width / 2, marker.height / 2],
    };
  },

  _route(route) {
    // debug('_route');
    if (!route) {
      return [];
    }
    return global.App.polyline.decode(route.polyline);
  },

  _waypoints(position) {
    return [
      {
        location: `${String(position[0])},${String(position[1])}`,
      },
    ];
  },

  _position(address) {
    if (!address) {
      return '';
    }
    return `${String(address[0])},${String(address[1])}`;
  },

  _setPosition(queryResult) {
    let position;
    if (Array.isArray(queryResult)) {
      position = [queryResult[0].latitude, queryResult[0].longitude];
    } else {
      const latitude = queryResult.latLng.lat();
      const longitude = queryResult.latLng.lng();
      position = [latitude, longitude];
    }
    return position;
  },

  _query(query) {
    if (query && query[0] && typeof query[0] === 'number' && query[1] && typeof query[1] === 'number') {
      return `${String(query[0])},${String(query[1])}`;
    }
    return query;
  },

  _requestAddress(position, callback) {
    if (!position) {
      return;
    }

    position = this._position(position);

    global.App.socketio.emit(
      '/shared/maps/address',
      {
        latlng: position,
      },
      (error, result) => {
        if (error) {
          callback(error);
          return;
        }

        callback(null, result[0]);
      },
    );
  },

  _requestAddressWithPromise(position) {
    // return this.promisify(this._requestAddress, arguments);
    return new Promise((resolve, reject) => {
      this._requestAddress(position, (error, address) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(address);
      });
    });
  },

  _reverseGeocodeAddress(position, callback) {
    if (!position) {
      callback();
      return;
    }

    position = this._position(position);

    global.App.socketio.emit(
      '/shared/maps/address',
      {
        latlng: position,
      },
      (error, result) => {
        if (error) {
          callback(error);
          return;
        }
        callback(null, result[0]);
      },
    );
  },

  _requestRoute(origin, destination, callback) {
    if (!origin || !destination) {
      callback();
      return;
    }

    global.App.socketio.emit(
      '/shared/maps/route',
      {
        origin: origin.join(','),
        destination: destination.join(','),
      },
      (error, result) => {
        if (error) {
          callback(error);
          return;
        }
        callback(null, result);
      },
    );
  },
};
