Polymer({
  is: 'account-reset-password-view',

  _entity: 'user',

  behaviors: [global.App.ElementBase],

  properties: {
    user: {
      type: Object,
      value() {
        return app.user;
      },
    },
  },

  _submit() {
    this.$$('#setPasswordButton').disabled = true;
    const body = this.$$('#form').serialize();

    global.App.fetch('/auth/password', {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.$$('#setPasswordButton').disabled = false;
        const user = Object.assign(app.user, {
          password: response.done,
        });
        this.set('user', user);

        global.App.EVENT.emit('view:refresh');

        this.issues = {};
        this._closeSideView();
      })
      .catch((error) => {
        this.$$('#setPasswordButton').disabled = false;
        this._fetchFailureHandler(error);
      });
  },

  _closeSideView() {
    app.$.state.goTo('profile');
  },
});
