Polymer({
  is: 'map-view',

  _entity: 'home',

  behaviors: [global.App.ElementBase, global.App.MapHelpers],

  properties: {
    selectedState: {
      type: String,
      value() {
        return 'request-order';
      },
    },
    taxiNearBy: {
      type: Array,
      notify: true,
      value() {
        return [];
      },
    },
    requestPosition: {
      type: Object,
      notify: true,
    },
    destinationPosition: {
      type: Object,
      notify: true,
      value: null,
    },
    fixingPosition: {
      type: Boolean,
      value: false,
    },
    _handleDirectionsResponseState: {
      type: Boolean,
      value: true,
    },
  },

  observers: [
    '_updateSearchPlaceholder(searchResults)',
    '_inputAddressChanged(search)',
    '_computedFitToMarkers(requestPosition,destinationPosition)',
  ],

  ready(event, detail) {
    // global.App.EVENT.on
    global.App.EVENT.on('select-requested-position', (response) => {
      this._fixRequestPosition();
    });

    global.App.EVENT.on('select-destination-position', (response) => {
      this._fixDestinationPosition();
    });

    global.App.EVENT.on('address-loaded', (response) => {
      if (!response) {
        return;
      }
      this.set('_handleDirectionsResponseState', false);
      const order = response.item;
      this.set('requestPosition', order.requestPosition);
      this.set('destinationPosition', order.destinationPosition);
      this.set('route', order.route);
    });

    global.App.EVENT.on('cancel-request-emmitted', (response) => {
      this._clearRequest();
    });

    global.App.socketio.on('client:order:taxi:state', (order) => {
      if (this.order && order.id !== this.order.id) {
        return;
      }
      this.splice.apply(this, ['taxiNearBy', 0, this.taxiNearBy.length].concat(order.taxi));
    });
  },

  attached() {
    this.async(this._getLocation, 1000);

    global.App.EVENT.on('action:open-tarification', () => {
      this._openPricing();
    });

    global.App.EVENT.on('action:open-howto', () => {
      this._openHowTo();
    });
  },

  _openPricing() {
    this.$.tarificationDialog.open();
  },

  _openHowTo() {
    this.$.howToDialog.open();
  },

  _computedFitToMarkers(requestPosition, destinationPosition) {
    if (!this.$.map) {
      return;
    }

    this.$.map.fitToMarkers = !(!requestPosition || !destinationPosition);
  },

  _computedAddressForm(address) {
    if (!address) {
      return '';
    }

    if (address.label) {
      return `${address.fullAddress}, ${address.label}`;
    }

    if (!address.label) {
      return `${address.fullAddress}, ${address.zone}`;
    }

    return '';
  },

  _updateSearchPlaceholder(searchResults) {
    let placeholder = !this.requestPosition ? 'Départ ' : 'Destination ';

    if (searchResults && searchResults.length) {
      placeholder = searchResults[0].zone;
    }

    this.set('searchPlaceholder', placeholder);
  },

  _inputAddressChanged(search) {
    if (this.destinationPosition || !this.search) {
      return;
    }

    const query = {
      radius: 10000,
    };

    if (Array.isArray(search)) {
      query.latlng = this._position(search);
    } else {
      query.place = search;
    }

    global.App.socketio.emit('/client/address/lookup', query, (error, result) => {
      if (error) {
        app.showToast(error.message);
        return;
      }

      this.set('searchResults', result.address);
    });
  },

  _computedSearchSuggestionsDropdownOpened(searchResults) {
    // @TODO NEED TO CHANGE THIS CONDITION AND ITS LOGICS!

    if (!this.$$('#pac-input')) {
      return false;
    }

    if (!searchResults.length) {
      return false;
    }

    if (typeof this.$$('#pac-input').bindValue === 'string') {
      this.$$('#pac-input').value = this.$$('#pac-input').bindValue;
    }

    return !!this.$$('#pac-input') && this.$$('#pac-input').value !== '' && !!searchResults;
  },

  _computedLayout(mobile) {
    return mobile ? '-layout-vertical' : '-layout-horizontal';
  },

  _computedSideWidth(mobile) {
    if (mobile) {
      return '-fit-height';
    }
    return '-fit-width';
  },

  _updateCenterListener() {
    if (!this.$.map) {
      return;
    }
    this._setCenter(this.$.map.map.getCenter().lat, this.$.map.map.getCenter().lng);
  },

  _setCenter(lat, lng) {
    if (this.destinationPosition || !this.$.map) {
      return;
    }

    const center = [lat, lng];
    this.set('center', center);

    if (this.requestPosition) {
      return;
    }

    global.App.socketio.emit(
      '/client/taxi/nearby',
      {
        position: this.center,
      },
      (error, result) => {
        if (error) {
          app.showToast(error.message);
          return;
        }
        const taxiSet = result.taxi || [];
        this.set('taxiNearBy', taxiSet);
      },
    );
  },

  _goToSelectedLocation(event, detail) {
    const { item } = event.model;
    this._updateSearchPlaceholder([item]);
    this._setCenter(item.position[0], item.position[1]);

    if (this.search) {
      this.set('search', '');
    }

    this.$.searchSuggestions.close();
  },

  _fixRequestPosition() {
    const position = [this.$.map.map.getCenter().lat, this.$.map.map.getCenter().lng];

    const lat = this.$.map.map.getCenter().lat - 0.004;
    const lng = this.$.map.map.getCenter().lng + 0.004;

    this.set('fixingPosition', true);

    setTimeout(() => {
      this.set('requestPosition', position);
      this.set('search', '');

      this._setCenter(lat, lng);
      this.set('zoom', this.zoom - 2);

      this.set('fixingPosition', false);

      global.App.EVENT.emit('select-request-position-success', {
        item: {
          position,
          fullAddress: 'Départ',
        },
      });
    }, 400);
  },

  _fixDestinationPosition() {
    const position = [this.$.map.map.getCenter().lat, this.$.map.map.getCenter().lng];
    this.set('fixingPosition', true);

    global.App.EVENT.emit('selecting-destination-position', {
      item: position,
      fullAddress: 'Destination',
    });

    setTimeout(() => {
      this.set('destinationPosition', position);

      this.set('fixingPosition', false);

      this._handleDirectionsResponse(null, null);
    }, 30);
  },

  _handleDirectionsResponse(event, detail) {
    if (!this._handleDirectionsResponseState) {
      return;
    }

    const requestAddress = {
      position: this.requestPosition,
      // fullAddress: response.routes[0].legs[0].start_address,
    };

    const destinationAddress = {
      position: this.destinationPosition,
      // fullAddress: response.routes[0].legs[0].end_address,
    };

    const requestAddressPromise = this._requestAddressWithPromise(this.requestPosition).then((address) => {
      requestAddress.fullAddress = address.fullAddress;
      requestAddress.zone = address.zone;
      return address;
    });

    const destinationAddressPromise = this._requestAddressWithPromise(this.destinationPosition).then((address) => {
      destinationAddress.fullAddress = address.fullAddress;
      destinationAddress.zone = address.zone;
      return address;
    });

    const routePromise = new Promise((resolve, reject) => {
      this._requestRoute(this.requestPosition, this.destinationPosition, (error, route) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(route);
      });
    });

    Promise.all([requestAddressPromise, destinationAddressPromise, routePromise])
      .then((result) => {
        const route = result[2];
        this.set('route', route);

        const order = {
          // costEstimate: this._computeOrderEstimate(entryParams.duration.value, entryParams.distance.value, now),
          requestAddress,
          destinationAddress,
          route,
        };

        global.App.EVENT.emit('order-loaded', order);
      })
      .catch((error) => {
        debug('promises-error..', error);
      });
  },

  _computeOrderEstimate(duration, distance, startedAt) {
    return global.App.EstimateUtils.calculateRideEstimate(distance, startedAt, duration);
  },

  _clearRequest() {
    setTimeout(() => {
      this.set('requestPosition', null);
      this.set('destinationPosition', null);
      this.set('route', null);
      this.set('_handleDirectionsResponseState', true);

      if (this.search) {
        this.set('search', '');
      }

      global.App.EVENT.emit('order-cancelling-success', {
        item: 'orderCancelled',
      });
    }, 500);
  },

  toggleMenu() {
    if (!global.menuDrawer) {
      return;
    }

    this.$.menuDrawer = global.menuDrawer;
    this.$.menuDrawer.togglePanel();
  },
});
