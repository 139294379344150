const VERIFIED_CLASS_SET = {
  true: {
    label: 'verifié',
    class: '-color-green',
  },
  false: {
    label: 'non verifié',
    class: '-color-grey',
  },
};

Polymer({
  is: 'profile-view',

  _entity: 'user',

  behaviors: [global.App.ElementBase],

  properties: {
    user: {
      type: Object,
      notify: true,
      value() {
        return { ...app.user };
      },
    },
    editUserName: {
      type: Boolean,
      notify: true,
      value: false,
    },
    editUserEmail: {
      type: Boolean,
      notify: true,
      value: false,
    },
    verifiedClasses: {
      type: Object,
      readOnly: true,
      value: VERIFIED_CLASS_SET,
    },
    googleClientId: {
      type: Object,
      readOnly: true,
      value: global.App.CONFIG.GOOGLE_ID,
    },
    facebookClientId: {
      type: Object,
      readOnly: true,
      value: global.App.CONFIG.FACEBOOK_ID,
    },
  },

  ready() {
    global.App.EVENT.on('view:refresh', (response) => {
      this.set('user', { ...app.user });

      if (!response) {
        return;
      }

      if (this.$.verifyPhoneDialog.opened) {
        this.$.verifyPhoneDialog.close();
      }
    });
  },

  attached() {
    this._getFetchedUser();
  },

  _getFetchedUser() {
    global.App.fetch('/user')
      .then((response) => {
        this._loaded();

        const user = Object.assign(app.user, response.user);
        this.set('user', {});
        this.set('user', user);
      })
      .catch((error) => {
        this._loaded();
        this._fetchFailureHandler(error);
      });
  },

  _setPassword(event, detail) {
    this.set('user.password', true);
  },

  _goChangePassword() {
    app.$.state.goTo('profile.password');
  },

  _goChangeAvatar() {
    app.$.state.goTo('profile.avatar');
  },

  _goSetName() {
    this.set('editUserName', this.user.name);
    this.set('editUserName', true);
  },

  _doCancelSaveName(event, detail) {
    this.set('editUserName', false);
  },

  _doSaveName() {
    const body = this.$$('#nameForm').serialize();
    global.App.fetch('/user/name', {
      method: 'POST',
      body,
    })
      .then((response) => {
        const user = Object.assign(app.user, {
          name: response.value,
        });
        this.set('user', {});
        this.set('user', user);
        this.set('editUserName', false);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _goSetEmail() {
    this.set('editUserEmail', true);
  },

  _doCancelSaveEmail() {
    this.set('editUserEmail', false);
  },

  _doSaveEmail() {
    const body = this.$$('#emailForm').serialize();
    global.App.fetch('/auth/email', {
      method: 'POST',
      body,
    })
      .then((response) => {
        const user = Object.assign(app.user, response.value);
        this.set('user', {});
        this.set('user', user);

        if (!user.emailVerified) {
          app.showToast('Nous vous enverrons un email de vérification pour valider votre compte!');
        }
        this.set('editUserEmail', false);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _goSetPhone() {
    this.$.verifyPhoneDialog.open();
  },

  _connectFacebook() {
    this._facebookAuthStatusCallback((response) => {
      global.App.fetch('/auth/link/facebook/token', {
        method: 'POST',
        body: {
          access_token: response.authResponse.accessToken,
        },
      })
        .then((response) => {
          const user = Object.assign(app.user, response.user);
          this.set('user', {});
          this.set('user', user);
        })
        .catch((error) => {
          this._fetchFailureHandler(error);
        });
    });
  },

  _disconnectFacebook() {
    this._facebookAuthStatusCallback((response) => {
      global.App.fetch('/auth/unlink/facebook/token', {
        method: 'POST',
        body: {
          access_token: response.authResponse.accessToken,
        },
      })
        .then((response) => {
          const user = Object.assign(app.user, response.user);
          this.set('user', {});
          this.set('user', user);
        })
        .catch((error) => {
          this._fetchFailureHandler(error);
        });
    });
  },

  _facebookAuthStatusCallback(callback) {
    global.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        callback(response);
      } else {
        global.FB.login((response) => {
          callback(response);
        });
      }
    });
  },

  _googleAuthStatusCallback(callback) {
    if (!global.GoogleAuth) {
      return; // AuthEngine not initialized. GoogleAuth/gapi has not loaded.
    }

    function _updateCurrentUser() {
      const currentUser = global.GoogleAuth.currentUser.get();

      if (!currentUser || !currentUser.getAuthResponse().access_token) {
        return null;
      }

      return {
        authResponse: currentUser.getAuthResponse(),
        currentUser: {
          name: currentUser.getBasicProfile().getName(),
          picture: currentUser.getBasicProfile().getImageUrl(),
          email: currentUser.getBasicProfile().getEmail(),
        },
      };
    }

    // Listen for sign-in state changes.
    global.GoogleAuth.isSignedIn.listen((response) => {
      callback(_updateCurrentUser());
    });

    // Listen for changes to current user.
    global.GoogleAuth.currentUser.listen((response) => {
      callback(_updateCurrentUser());
    });

    // Sign in the user if they are not currently signed in.
    if (global.GoogleAuth.isSignedIn.get() === false) {
      global.GoogleAuth.signIn();
    }

    // Start with the current live values.
    callback(_updateCurrentUser());
  },

  _disconnectGoogle() {
    this._googleAuthStatusCallback((response) => {
      global.App.fetch('/auth/unlink/google/token', {
        method: 'POST',
        body: {
          access_token: response.authResponse.access_token,
        },
      })
        .then((response) => {
          const user = Object.assign(app.user, response.user);
          this.set('user', {});
          this.set('user', user);
        })
        .catch((error) => {
          this._fetchFailureHandler(error);
        });
    });
  },

  _connectGoogle() {
    // @TODO if !response.authResponse.access_token? enhance google listener...
    this._googleAuthStatusCallback((response) => {
      global.App.fetch('/auth/link/google/token', {
        method: 'POST',
        body: {
          access_token: response.authResponse.access_token,
        },
      })
        .then((response) => {
          const user = Object.assign(app.user, response.user);
          this.set('user', {});
          this.set('user', user);
        })
        .catch((error) => {
          this._fetchFailureHandler(error);
        });
    });
  },
});
