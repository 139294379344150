
require('./leaflet-core.html');

require('./leaflet-draw.html');

require('./leaflet-control.html');

require('./leaflet-layer.html');

require('./leaflet-layer-group.html');

require('./leaflet-geojson.html');

require('./leaflet-marker.html');

require('./leaflet-geolocation.html');
