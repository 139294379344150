// const merge = require('lodash/merge');
const mergeWith = require('lodash/mergeWith');

const CONST = require('./const');

function mergeCustomizer(objValue, srcValue) {
  if (objValue === CONST) {
    return undefined;
  }
  if (srcValue === CONST) {
    return undefined;
  }
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

exports.merge = function (object, ...sources) {
  return mergeWith(object, ...sources, mergeCustomizer);
};
