Polymer({
  is: 'profile-avatar-view',

  behaviors: [global.App.ElementBase],

  properties: {
    avatarSet: {
      type: Array,
      readOnly: true,
      value: ['01', '02', '03', '04', '05', '06', '07', '08'],
    },
    slectedAvatar: {
      type: String,
      notify: true,
    },
    user: {
      type: Object,
      value() {
        return { ...app.user };
      },
    },
  },

  _submit() {
    this.$$('#setAvatarButton').disabled = true;

    global.App.fetch('/user/picture', {
      method: 'POST',
      body: {
        picture: this.slectedAvatar,
      },
    })
      .then((response) => {
        this.$$('#setAvatarButton').disabled = false;
        const user = Object.assign(app.user, response);
        this.set('user', user);

        global.App.EVENT.emit('view:refresh');

        this._closeSideView();
      })
      .catch((error) => {
        this.$$('#setAvatarButton').disabled = false;
        this._fetchFailureHandler(error);
      });
  },

  _setAvatar(event, detail) {
    // debug(event.model._nodes[0]._resolvedSrc);
    const slectedAvatar = `https://media.etaxi.tn/s/avatar/${event.model.item}.png`;
    this.set('slectedAvatar', slectedAvatar);
  },

  _closeSideView() {
    app.$.state.goTo('profile');
  },
});
