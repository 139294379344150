Polymer({
  is: 'password-reset-request-view',

  behaviors: [global.App.ElementBase],

  properties: {
    resetStatus: {
      type: Boolean,
      notify: true,
      value: false,
    },
  },

  _submit() {
    const body = this.$$('#form').serialize();

    global.App.fetch(`/auth/reset/password/request?${global.App.CONFIG.AUTH_QUERY}`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.set('resetStatus', true);
        this.issues = {};
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _goLogin(event, detail) {
    app.$.state.goTo('login');
  },
});
