require('whatwg-fetch');

const EVENT = require('./event');
const CONFIG = require('./config');

const { fetch, Request } = global;

function urlEncodeObject(result, name, value) {
  if (typeof value === 'object' && value) {
    if (typeof value.toJSON === 'function') {
      result[name] = value.toJSON();
    } else if (Array.isArray(value)) {
      value.forEach((val, index) => {
        urlEncodeObject(result, `${name}[${index}]`, val);
      });
    } else {
      Object.keys(value).forEach((key) => {
        urlEncodeObject(result, `${name}[${key}]`, value[key]);
      });
    }
  } else {
    result[name] = value;
  }
}

function queryString(query) {
  const queryParts = {};
  Object.keys(query).forEach((param) => {
    const value = query[param];
    urlEncodeObject(queryParts, param, value);
  });
  return Object.keys(queryParts)
    .map((param) => {
      const value = queryParts[param];
      return `${param}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

const DEFAULT_CONNECTION_FAILURE_MESSAGE = 'Failed to fetch';

function getToken() {
  return global.app ? global.app.token : null;
}

function goFetch(input, init) {
  let request;

  // eslint-disable-next-line no-prototype-builtins
  if (Request.prototype.isPrototypeOf(input) && !init) {
    request = input;
  } else {
    if (input.charAt(0) === '/') {
      input = CONFIG.ENDPOINT + input;
    }

    init = init || {};

    if (init.query) {
      if (typeof init.query === 'object') {
        input += (input.indexOf('?') === -1 ? '?' : '&') + queryString(init.query);
      } else if (typeof init.query === 'string') {
        input += (input.indexOf('?') === -1 ? '?' : '&') + encodeURIComponent(init.query);
      }
    }

    init.headers = init.headers || {};

    if (input.indexOf(CONFIG.ENDPOINT) === 0) {
      if (!('Content-Type' in init.headers)) {
        init.headers['Content-Type'] = 'application/json';
      }

      if (!('Authorization' in init.headers) && getToken()) {
        init.headers.Authorization = `Bearer ${getToken()}`;
      }
    }

    if (init.body && !init.method) {
      init.method = 'POST';
    }

    if (init.method === 'POST' && typeof init.body !== 'string' && init.body) {
      const cache = [];
      init.body = JSON.stringify(init.body, (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return undefined;
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
    }

    request = new Request(input, init);
  }

  debug(request.method, request.url, 'request');

  // eslint-disable-next-line no-use-before-define
  return fetch(request).then(responseHandlerFactory(request), errorHandlerFactory(request));
}
module.exports = goFetch;

function responseHandlerFactory(request) {
  return function responseHandler(response) {
    // debug(request.method, request.url, 'response', response.status, response.headers.get('Content-Type'));

    let result;

    const isJSON = String(response.headers.get('Content-Type')).indexOf('application/json') === 0;

    if (isJSON) {
      result = response
        .json()
        .then((data) => {
          debug(request.method, request.url, 'response', response.status, Object.keys(data));
          return data;
        })
        .catch((error) => {
          debug(request.method, request.url, 'response', response.status, error);
          return Promise.reject(error);
        });
    } else {
      result = response.text();
    }

    if (!response.ok) {
      if (response.status === 401) {
        if (getToken()) {
          EVENT.emit(':logout');
        }
      } else if (response.status === 403) {
        EVENT.emit('unauthorized');
      }

      // return result.then(Promise.reject.bind(Promise));

      if (isJSON) {
        return result.then((error) => {
          // throw new ERROR.RequestError(error, 'FetchError', error.message, {
          //   request: request,
          //   response: response,
          // });
          throw error;
        });
      }
      return result.then((error) => {
        // throw new ERROR.RequestError(error, 'FetchError', error, {
        //   request: request,
        //   response: response,
        // });
        throw error;
      });
    }

    return result;
  };
}

function errorHandlerFactory(request) {
  return function errorHandler(error) {
    debug(request.method, request.url, 'error', error);
    // return Promise.reject(error);
    if (error.message === DEFAULT_CONNECTION_FAILURE_MESSAGE) {
      error.message = 'Echec de connexion';
    }
    // throw new ERROR.RequestError(error, 'FetchError', error.message, {
    //   request: request,
    // });
    throw error;
  };
}

// function responseHandler(response) {
//   debug('responseHandler', response);

//   let result = response.json();

//   if (!response.ok) {
//     if (response.status === 401) {
//       if (getToken()) {
//         EVENT.emit(':logout');
//       }
//     }

//     // return result.then(Promise.reject.bind(Promise));

//     return result.then(function (error) {
//       debug('fetch', 'global-error', error);
//       throw error;
//       // throw new ERROR.RequestError(error, 'FetchError', error.message, {
//       //   response: response,
//       // });
//     });
//   }
//   return result;
// }

// function errHandler(error) {
//   debug('errHandler', error);
//   // return Promise.reject(error);
//   throw error;
// }
