global.App.UI = {
  properties: {
    activityCount: {
      type: Number,
      value: 0,
    },
    toastActionLabel: {
      type: String,
      value: '',
    },
  },

  showActivity() {
    this.activityCount++;
    this.set('activityCount', this.activityCount);
  },

  clearActivity() {
    if (this.activityCount) {
      this.activityCount--;
      this.set('activityCount', this.activityCount);
    }
  },

  showToast(message, options) {
    options = options || {};
    options.duration = options.duration || 6000;

    if (options.action) {
      this.set('toastActionLabel', options.actionLabel);
      this._toastActionCallback = options.action || function () {};
      this.$.toastWidthAction.show({
        text: message,
        duration: options.duration,
      });
    } else {
      this.$.toast.show({
        text: message,
        duration: options.duration,
      });
    }
  },

  closeActionToast(event) {
    this.$.toastWidthAction.close();
    this._toastActionCallback();
  },

  closeMenu() {
    this.$.menuDrawer.closeDrawer();
  },

  openMenu() {
    this.$.menuDrawer.openDrawer();
  },

  toggleMenu() {
    this.$.menuDrawer.togglePanel();
  },

  openSideView() {
    this.$.sideViewDrawer.openDrawer();
    this.$.sideViewDrawer.forceNarrow = false;
  },

  closeSideView() {
    this.$.sideViewDrawer.closeDrawer();
    this.$.sideViewDrawer.forceNarrow = true;
  },

  backSideView() {
    if (this.$.state.history.length) {
      this.$.state.back();
    } else {
      this.closeSideView();
    }
  },

  scrollMainViewToTop: function scrollPageToTop() {
    this.$.headerPanelMain.scrollToTop(true);
  },

  scrollSubViewToTop: function scrollPageToTop() {
    // @TODO implement
  },

  // fireSearchAction: function () {
  //   this.$.searchInput = global.app.$.searchInput;
  //   var query = this.$.searchInput.value;
  //   global.App.EVENT.emit(':search', { item: query, });
  // },

  _openSearchSide(event, detail) {
    // debug('_openSearchSide', this.$.searchInput.value)
    this.$.state.goTo(`${this.$.state.name}.search`);
    global.App.EVENT.emit(':search', {
      item: this.$.searchInput.value,
    });
  },

  _errorHandler(event, detail) {
    console.error('_errorHandler', arguments);
    this.showToast(detail.message);
  },
};
