const debug = require('debug')('etaxi:app-state');

const page = require('page');

Polymer({
  is: 'app-state',

  properties: {
    stateSet: {
      type: Object,
      value() {
        return {
          // 'name': {
          //   title: "Title",
          //   url: '/url/:entityId',
          //   public: true
          // }, .
        };
      },
    },

    name: {
      type: String,
      notify: true,
      // readOnly: true,
      reflectToAttribute: true,
    },

    title: {
      type: String,
      notify: true,
      // readOnly: true,
    },

    params: {
      type: Object,
      notify: true,
      // readOnly: true,
      value() {
        return {};
      },
    },

    data: {
      type: Object,
      notify: true,
      // readOnly: true,
      value() {
        return {};
      },
    },

    sub: {
      type: Object,
      notify: true,
      // readOnly: true,
      value: null,
    },

    current: {
      type: Object,
      notify: true,
      // readOnly: true,
      value() {
        return {};
      },
    },

    last: {
      type: Object,
      notify: true,
      // readOnly: true,
      value: null,
    },

    history: {
      type: Array,
      notify: true,
      // readOnly: true,
      value() {
        return [];
      },
    },
  },

  clone() {
    return this.name
      ? {
        url: this.url,
        path: this.path,
        name: this.name,
        title: this.title,
        params: this.params,
        query: this.query,
        data: this.data,
        sub: this.sub,
      }
      : null;
  },

  update: function state_set(name, config, noHistory) {
    config = config || {};
    const context = config.context || {};
    this.url = (this.stateSet[name] || {}).url;
    this.name = name || config.name;
    this.title = config.title || (this.stateSet[this.name] || {}).title || '';
    this.path = context.path;
    this.params = { ...context.params };
    this.query = { ...context.query };
    this.data = config.data || {};
    if (config.sub) {
      this.setSub(config.sub.name, config.sub);
    } else {
      this.sub = null;
    }
  },

  go: function state_go(name, config, noHistory) {
    if (!config) {
      config = {};
    }
    if (!config.name) {
      config.name = name;
    }
    if (!noHistory) {
      this.last = this.clone();
      this.push('history', this.clone());
    }
    this.fire('state-changing', config);
    global.App.EVENT.emit('state-changing', config);
    this.update.apply(this, arguments);
    this.current = this.clone();
    this.fire('state-change', this);
    global.App.EVENT.emit('state-change', this);
  },

  setSub: function state_gosub(name, config) {
    this.sub = {};
    this.sub.name = name || config.name;
    this.sub.title = config.title || (this.stateSet[this.sub.name] || {}).title || '';
    this.sub.data = config.data || {};
  },

  back: function state_back() {
    const state = this.history.pop();
    if (state) {
      this.go(state.name, state, true);
      this.last = this.history[this.history.length - 1] || null;
    }
  },

  clear: function state_clear() {
    this.last = null;
    this.history.length = 0;
  },

  makeHandler(stateName) {
    return function (context) {
      this.go(stateName, {
        context,
      });
    };
  },

  goTo(state, params, query) {
    debug('state.goTo', state, params, query);
    const _state = this.stateSet[state];
    if (!_state) {
      const error = new Error(`Undefined state ${state}!`);
      throw error;
    }
    let { url } = this.stateSet[state];
    if (params) {
      if (typeof params !== 'object') {
        const p = {};
        const parts = state.split('.');
        p[`${parts[parts.length - 2]}Id`] = params;
        params = p;
      }
      Object.keys(params).forEach((param) => {
        url = url.replace(`:${param}`, params[param]);
      });
    }
    if (query) {
      url += `?${Object.keys(query)
        .map((param) => `${param}=${query[param]}`)
        .join('&')}`;
    }
    page.show(`#!${url}`);
  },

  setup() {
    const that = this;

    function setupRoute(state) {
      // page(state.url, state.handler ? state.handler.bind(that) : that.makeHandler(state.name).bind(that));
      let handlers = state.middleware || [];
      if (!Array.isArray(handlers)) {
        handlers = [handlers];
      }
      handlers.push(state.handler || that.makeHandler(state.name));
      handlers = handlers.map((handler) => handler.bind(that));
      handlers.unshift(state.url);
      page(...handlers);
    }

    page((context, next) => {
      window.debug('route changed', Object.assign(context));
      next();
    });

    // parse query params
    page((context, next) => {
      const query = {};
      if (context.querystring) {
        context.querystring.split('&').forEach((value) => {
          value = value.split('=');
          query[value[0]] = value.length === 2 ? value[1] : true;
        });
      }
      context.query = query;
      next();
    });

    // Routes

    const states = Object.keys(this.stateSet).map((name) => {
      const state = that.stateSet[name];
      state.name = name;
      return state;
    });

    // states.forEach(function(state) {
    //   debug('setting up routing for state', state.name, state.url);
    //   setupRoute(state);
    // });

    states
      .filter((state) => state.public)
      .forEach((state) => {
        debug('setting up routing for public state', state.name, state.url);
        setupRoute(state);
      });

    page('*', (context, next) => {
      if (app && app.isAuthenticated && !app.isAuthenticated()) {
        that.goTo('login');
        return;
      }
      next();
    });

    states
      .filter((state) => !state.public)
      .forEach((state) => {
        debug('setting up routing for private state', state.name, state.url);
        setupRoute(state);
      });

    // handle 404
    page('*', (context, next) => {
      debug('route not found', context);
      next();
    });

    page('*', '/');

    page({
      hashbang: true,
    });
  },
});
