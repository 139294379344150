Polymer({
  is: 'booking-request-alert',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    locale: {
      type: String,
      readOnly: true,
      value: 'fr',
    },
    requestedAtDate: {
      type: String,
      notify: true,
      value() {
        return global.App.moment()
          .add(1, 'hour')
          .startOf('hour')
          .toDate();
      },
    },
    maxrequestedAtDate: {
      type: String,
      notify: true,
      value() {
        const now = new Date();
        const maxrequestedAtDate = now;
        maxrequestedAtDate.setDate(now.getDate() + 30);
        return maxrequestedAtDate;
      },
    },
    minrequestedAtDate: {
      type: String,
      notify: true,
      value() {
        return new Date();
      },
    },
    requestedAtTime: {
      type: String,
      notify: true,
      value() {
        return global.App.moment()
          .add(1, 'hour')
          .startOf('hour')
          .toDate();
      },
    },
  },

  observers: ['_formatRequestedAtTime(requestedAtTime)'],

  _formatRequestedAtTime(requestedAtTime) {
    if (requestedAtTime instanceof Date) {
      return;
    }

    const scheduleMoment = global.App.moment(requestedAtTime, 'HH:mm z');
    this.set('requestedAtTime', scheduleMoment.toDate());
  },

  _openDatepicker() {
    this.$$('#datepicker').open();
  },

  _openTimepicker() {
    this.$$('#timepicker').open();
  },

  _continue() {
    const requestedAt = global.App.moment.parseZone(
      `${global.App.moment(this.requestedAtDate).format('YYYY-MM-DD')}T${global.App.moment(this.requestedAtTime).format(
        'HH:mm',
      )}+01:00`,
    );

    if (!global.App.moment(requestedAt).isValid() || this._validateRequestAt(requestedAt)) {
      this.issues = {
        booking: {
          requestedAt: 'invalide',
        },
      };
      app.showToast("Vérifier l'heure de la réservation!");
      return;
    }

    global.App.EVENT.emit(':continue:booking-request', {
      requestedAt: requestedAt.toISOString(),
    });

    // debug('requestedAt-continue', requestedAt.toISOString(), requestedAt);
  },

  _close() {
    global.App.EVENT.emit(':continue:booking-request');
  },

  _computedDelay(datetime, now) {
    now = now || new Date();
    const diff = global.App.moment(datetime, 'DD/MM/YYYY HH:mm:ss').diff(global.App.moment(now, 'DD/MM/YYYY HH:mm:ss'));
    return diff;
  },

  _validateRequestAt(datetime, distance) {
    distance = distance || 30 * 60 * 1000; // 30min [ms]
    return this._computedDelay(datetime) < distance;
  },
});
