global.CACHE = {};

Polymer({
  is: 'app-cache',

  storage: global.CACHE,

  get(key) {
    return this.storage[key];
  },

  set(key, value) {
    this.storage[key] = value;
  },

  clear() {
    this.storage = global.CACHE = {};
  },
});
