global.App.Auth = {
  properties: {
    user: {
      type: Object,
      value: null,
    },
    token: {
      type: String,
      value: '',
    },
    authenticated: {
      type: Boolean,
      value: false,
    },
  },

  isAuthenticated() {
    return !!this.token && this.authenticated;
  },

  _loginSuccess(event, detail) {
    this._login(detail);
  },

  _login(response) {
    // debug('detail.._loginSuccess', response);
    this.set('user', response.user);
    this.set('token', response.token);
    this.set('authenticated', true);

    // this.showToast('Welcome back ' + this.user.name + ' !');
    this.$.state.goTo('home');
    this.setupConnection();
  },

  logout: function logout(message) {
    // debug('currentOrder', this.$.currentOrder.value, this.$.currentOrder);

    if (typeof message !== 'string') {
      message = 'A bientôt!';
    }

    if (this.$.currentOrder && this.$.currentOrder.value) {
      this.$.currentOrder.value = null;
    }

    if (this.$.selectedState && this.$.selectedState.value) {
      this.$.selectedState.value = null;
    }

    global.App.socketio.disconnect();

    if (global.FB) {
      global.FB.logout();
    }

    if (global.GoogleAuth) {
      global.GoogleAuth.disconnect();
    }

    // @TODO : call /auth/logout

    this.set('user', null);
    this.set('token', null);
    this.set('authenticated', false);

    this.$.state.clear();

    const tmp = {};
    tmp['etaxi.authCredentials'] = window.localStorage['etaxi.authCredentials'];
    tmp.debug = window.localStorage.debug;
    window.localStorage.clear();
    Object.assign(window.localStorage, tmp);

    this.$.state.goTo('login');
    this.showToast(message);
  },

  userLoad() {
    this.user = null;
  },

  tokenLoad() {
    this.token = null;
  },
};
