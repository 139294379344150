const DEMO_ITEM_SET = [
  {
    id: 1,
    text: '01-some text goes here...',
  },
  {
    id: 2,
    text: '02-some text goes here...',
  },
  {
    id: 3,
    text: '03-some text goes here...',
  },
  {
    id: 4,
    text: '04-some text goes here...',
  },
  {
    id: 5,
    text: '05-some text goes here...',
  },
];

Polymer({
  is: 'howto-view',

  behaviors: [global.App.ElementBase],

  properties: {
    demoItemSet: {
      type: Array,
      readOnly: true,
      value: DEMO_ITEM_SET,
    },
    selectedStep: {
      type: String,
      notify: true,
      value() {
        return DEMO_ITEM_SET[0].id;
      },
    },
  },

  _computedUrl(id) {
    const url = this.mobile ? 'images/howto/howto-:param.png' : 'images/howto/howto-bg-:param.png';
    return this._url(url, id);
  },

  _computedIndex(selectedStep) {
    const index = this.demoItemSet.findIndex((o) => o.id === selectedStep);

    if (index > -1) {
      return index + 1;
    }

    return 0;
  },

  _goNextPage() {
    const index = Number(this.selectedStep) + 1;
    this.set('selectedStep', index);
  },

  _goFirstPage() {
    const index = DEMO_ITEM_SET[0].id;
    this.set('selectedStep', index);
  },

  _goLastPage() {
    const index = DEMO_ITEM_SET[DEMO_ITEM_SET.length - 1].id;
    this.set('selectedStep', index);
  },

  _goPreviousePage() {
    const index = Number(this.selectedStep) - 1;
    this.set('selectedStep', index);
  },
});
