<link rel="import" href="../polymer/polymer.html">
<link rel="import" href="../neon-animation/neon-animation-behavior.html">
<link rel="import" href="../neon-animation/web-animations.html">

<script>
  Polymer({
    is: 'datepicker-slide-from-left-animation',

    behaviors: [
      Polymer.NeonAnimationBehavior
    ],

    configure: function(config) {
      var node = config.node;
      if (config.transformOrigin) {
        this.setPrefixedProperty(node, 'transformOrigin', config.transformOrigin);
      } else {
        this.setPrefixedProperty(node, 'transformOrigin', '0 50%');
      }
      this._effect = new KeyframeEffect(node, [
        {offset: 0.0, 'transform': 'translateX(-100%)', 'opacity': 0},
        {offset: 0.50, 'transform': 'translateX(-40%)', 'opacity': 0},
        {offset: 0.8, 'transform': 'translateX(-20%)', 'opacity': 0.6},
        {offset: 1.0, 'transform': 'none', 'opacity': 1},
      ], this.timingFromConfig(config));
      return this._effect;
    }
  });

  Polymer({
    is: 'datepicker-slide-from-right-animation',
    behaviors: [
      Polymer.NeonAnimationBehavior
    ],
    configure: function(config) {
      var node = config.node;
      if (config.transformOrigin) {
        this.setPrefixedProperty(node, 'transformOrigin', config.transformOrigin);
      } else {
        this.setPrefixedProperty(node, 'transformOrigin', '0 50%');
      }
      this._effect = new KeyframeEffect(node, [
        {offset: 0.0, 'transform': 'translateX(100%)', 'opacity': 0},
        {offset: 0.50, 'transform': 'translateX(40%)', 'opacity': 0},
        {offset: 0.8, 'transform': 'translateX(20%)', 'opacity': 0.6},
        {offset: 1.0, 'transform': 'none', 'opacity': 1},
      ], this.timingFromConfig(config));
      return this._effect;
    }
  });
</script>
