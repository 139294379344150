const socketio = require('socket.io-client');

Polymer({
  is: 'x-app',

  behaviors: [global.App.ElementBase, global.App.Auth, global.App.UI],

  properties: {},

  ready() {
    // eslint-disable-next-line no-use-before-define
    INIT_APP();
  },

  _showTitle(mobile, state) {
    return state && state.name !== 'home';
  },

  _showLogo(mobile, state) {
    return !mobile && state && state.name === 'home';
  },

  _computedCover() {
    return global.App.EstimateUtils.isSurcharged() ? '-background-image-night' : '-background-image-day';
  },

  _showMobileSearch(mobile, state) {
    return !!mobile && (!state || state.name === 'home');
  },

  _toolbarVisible(mobile, state) {
    return !mobile || (state && state.name !== 'home');
  },
});

function INIT_APP() {
  const app = document.querySelector('#app');

  app.name = 'E-Taxi';

  // FACEBOOK_SDK_INIT
  global.fbAsyncInit = function () {
    global.FB.init({
      appId: global.App.CONFIG.FACEBOOK_ID,
      xfbml: false,
      version: 'v2.8',
    });
  };

  if (!global.FB) {
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/fr_FR/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  // GOOGLE_SDK_INIT
  if (global.gapi) {
    global.gapi.load('auth2', () => {
      global.GoogleAuth = global.gapi.auth2.init({
        client_id: global.App.CONFIG.GOOGLE_ID,
        scope: 'https://www.googleapis.com/auth/plus.me',
      });
    });
  }

  function makeItemHandler(stateName, subState) {
    const param = `${stateName}Id`;
    if (!subState) {
      subState = 'detail';
    }
    return function (context) {
      let id;

      let data;
      if (subState !== 'add') {
        id = context.params[param];
        data = {};
        data[stateName] = app.$.cache.get(id) || {
          id,
        };
      }
      app.$.state.go(stateName, {
        context,
        sub: {
          name: `${stateName}.${subState}`,
          data,
        },
      });
    };
  }

  app.STATES = {
    about: {
      url: '/about',
      title: 'Information',
      public: true,
    },
    login: {
      url: '/login',
      title: 'Connexion',
      public: true,
    },
    account: {
      url: '/account',
      title: 'Connexion',
      public: true,
    },
    auth: {
      url: '/auth?token=:tokenId',
      title: 'Connexion',
      handler() {
        return function (context) {
          debug('auth?token', context.query.token);
        };
      },
    },
    'password.reset-request': {
      url: '/reset/password',
      title: 'Réinitialisation de mot de passe',
      public: true,
    },
    'password.reset': {
      url: '/reset/password/:token',
      title: 'Réinitialisation de mot de passe',
      public: true,
    },
    'account.reset-request': {
      url: '/reset/account/request',
      title: 'Réinitialisation de compte',
      public: true,
    },
    'account.reset': {
      url: '/reset/account',
      title: 'Réinitialisation de compte',
      public: true,
    },
    'account.emailVerification': {
      url: '/auth/verify/email',
      title: "Vérification d'email",
      public: true,
    },
    'account.phoneVerification': {
      url: '/auth/verify/phone',
      title: 'Vérification de Num. de Tél.',
      public: true,
    },
    logout: {
      url: '/logout',
      handler() {
        app.logout();
      },
    },
    profile: {
      url: '/profile',
      title: 'Profil',
    },
    'profile.avatar': {
      url: '/profile/avatar',
      title: 'Changer avatar',
      handler: makeItemHandler('profile', 'avatar'),
    },
    'profile.password': {
      url: '/profile/password',
      title: 'Changer mot de passe',
      handler: makeItemHandler('profile', 'password'),
    },
    home: {
      url: '/',
      title: 'Commande',
    },
    history: {
      url: '/history',
      title: 'Historique',
    },
    order: {
      url: '/order',
      title: 'Courses',
    },
    booking: {
      url: '/booking',
      title: 'Réservations',
    },
    // 'order.detail': {
    //   url: '/order/:orderId',
    //   title: "Course",
    //   handler: makeItemHandler('order'),
    // }
    howto: {
      url: '/howto',
      title: 'Comment ça marche?',
    },
    pricing: {
      url: '/pricing',
      title: 'Tarification',
    },
  };

  app.goToState = function (event, detail) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    const target = Polymer.dom(event).path.find((element) => element.dataset && element.dataset.state);
    const state = target ? target.dataset.state || 'none' : 'none';
    debug('goToState', target, state);
    app.$.state.goTo(state);
    // app.$.state.go(state);
    // if (state !== 'none') {
    //   history.pushState({}, null, '#!' + app.$.state.stateSet[state].url);
    // }
  };

  app.goLogin = function goLogin(event) {
    event.stopPropagation();
    app.$.state.goTo('login');
  };

  app.goProfile = function goProfile() {
    app.$.state.goTo('profile');
  };

  global.App.EVENT.on(':logout', () => {
    app.logout('Session expirée!');
  });

  app.init = function init() {
    app.authenticated = !!app.token;
    // debug('auth check', app.token, app.authenticated);
    if (app.isAuthenticated()) {
      app.setupConnection();
    }

    app.$.state.addEventListener('state-changing', (event) => {
      event.stopImmediatePropagation();
      const state = event.detail;

      debug('state-changing', state);

      if (state.last && state.name === state.last.name) {
        app.$.mainSelector.selected = 'none';
        app.$.sideViewSelector.selected = 'none';
      } else {
        app.scrollMainViewToTop();
      }
      if (!state.sub || (state.last && state.last.sub && state.sub.name === state.last.sub.name)) {
        app.$.sideViewSelector.selected = 'none';
      } else {
        app.scrollSubViewToTop();
      }
    });

    app.$.state.addEventListener('state-change', (event) => {
      const state = event.detail;
      debug('state-changed', app.state);

      if (global.ga) {
        global.ga(
          'send',
          'event',
          'View',
          state.sub ? state.sub.name : state.name,
          state.sub ? state.sub.title : state.title,
          state,
        );
        global.ga('set', 'page', app.state.url);
        global.ga('send', 'pageview');
      }
      app.closeMenu();
      if (state.sub) {
        app.openSideView();
      } else {
        app.closeSideView();
      }
      document.title = `${app.name} | ${state.title}`;
    });

    this.$.state.setup();
  };

  app.setupConnection = function setupConnection() {
    global.App.socketio = socketio.connect(`${global.App.CONFIG.ENDPOINT}/_`, {
      transports: ['websocket'],
      upgrade: false,
      query: {
        token: app.token,
      },
    });

    global.App.socketio.on('connect', () => {
      app.showToast('Connecté!');

      global.App.socketio.emit('/client/order/current', {}, (error, result) => {
        if (error) {
          return;
        }

        debug('current', result);

        if (!result.order) {
          return;
        }

        global.App.EVENT.emit('order-loaded', result.order);
      });
    });

    global.App.socketio.on('disconnect', function () {
      debug('disconnect', arguments);
      app.showToast('Déconnecté!');
    });

    global.App.socketio.on('connect_error', function (error) {
      debug('connect_error', arguments);
    });

    global.App.socketio.on('error', (error) => {
      debug('error', error);
      if (error === 'unauthenticated') {
        global.App.EVENT.emit(':logout');
      } else if (error === 'logout') {
        global.App.EVENT.emit(':logout');
      } else {
        app.showToast('Echec de connection!');
      }
    });

    global.App.socketio.on('unauthenticated', function () {
      debug('unauthenticated', arguments);
      global.App.EVENT.emit(':logout');
    });

    global.App.socketio.on(':logout', function () {
      debug(':logout', arguments);
      global.App.EVENT.emit(':logout');
    });
  };

  window.addEventListener(
    'WebComponentsReady',
    function () {
      debug('WebComponentsReady');
      this.init();
    }.bind(app),
  );

  app.addEventListener('dom-change', () => {
    debug('app', 'dom-change');

    if (global.App.CONFIG.name !== 'development') {
      return;
    }

    // log all unregistered custom elements
    Array.from(document.querySelectorAll('html /deep/ *'))
      .filter((el) => (el.localName.indexOf('-') !== -1 || el.getAttribute('is')) && el.constructor === HTMLElement)
      .forEach((el) => console.warn('Found unregistered custom element:', el));
  });
}
