
require('../polymer/polymer.html');

require('./animations/cascaded-animation.html');

require('./animations/fade-in-animation.html');

require('./animations/fade-out-animation.html');

require('./animations/hero-animation.html');

require('./animations/opaque-animation.html');

require('./animations/ripple-animation.html');

require('./animations/reverse-ripple-animation.html');

require('./animations/scale-down-animation.html');

require('./animations/scale-up-animation.html');

require('./animations/slide-from-left-animation.html');

require('./animations/slide-from-right-animation.html');

require('./animations/slide-from-top-animation.html');

require('./animations/slide-from-bottom-animation.html');

require('./animations/slide-left-animation.html');

require('./animations/slide-right-animation.html');

require('./animations/slide-up-animation.html');

require('./animations/slide-down-animation.html');

require('./animations/transform-animation.html');
