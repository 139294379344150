Polymer({
  is: 'login-form',

  behaviors: [global.App.ElementBase],

  properties: {},

  do() {
    const body = this.$$('#form').serialize();
    global.App.fetch(`/auth/login?${global.App.CONFIG.AUTH_QUERY}`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.fire('success', response);
        this.issues = {};
      })
      .catch(this._fetchFailureHandler.bind(this));
  },

  authCredentialsLoad() {
    this.authCredentials = {};
  },

  _fetchFailureHandler(error) {
    this.issues = error.issues;
    app.showToast(error.message);

    // if (error.code === 'SignupFailureUsedCredentials') {

    //   if (error.issues.user.phone) {
    //     app.showToast(error.message, {
    //       actionLabel: "Récupérer compte",
    //       action: function() {
    //         app.$.state.goTo('account.reset-request');
    //       },
    //     });
    //   } else {
    //     app.showToast(error.message, {
    //       actionLabel: "Réinitialiser mot de passe",
    //       action: function() {
    //         app.$.state.goTo('password.reset-request');
    //       },
    //     });
    //   }
    // } else {
    //   app.showToast(error.message);
    // }
  },
});
