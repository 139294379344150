
require('../../bower_components/app-datepicker/app-datepicker-dialog.html');

require('../../bower_components/compound-timepicker/compound-timepicker-dialog.html');

const RegisterHtmlTemplate = require('polymer-webpack-loader/register-html-template');

RegisterHtmlTemplate.register("<dom-module id=date-range-selection> <template> <style include=shared-styles></style> <style>:host{display:block;@apply (--layout-horizontal);@apply (--layout-around-justified);}.-border{@apply (--layout);@apply (--layout-horizontal);border-radius:2px;border:1px solid #e0e0e0;padding:4px;margin:4px 8px 4px 8px}.-border div{font-size:22px;font-weight:200;padding:4px}.-big{height:32px;width:32px;color:#e0e0e0}</style> <div class=flex></div> <div class=-border on-tap=_openFromDatepicker> <div class=\"self-center -text-uppercase\"> {{$date(_from)}} </div> </div> <div class=self-center i-on-tap=_openFromDatepicker> <iron-icon icon=arrow-forward></iron-icon> </div> <div class=-border on-tap=_openUntilDatepicker> <div class=\"self-center -text-uppercase\"> {{$date(_until)}} </div> </div> <div class=flex></div> <app-datepicker-dialog id=fromDatepicker with-backdrop=\"\" date={{_from}} max-date={{_until}} locale=[[locale]] disable-days=[] format=yyyy-mm-dd></app-datepicker-dialog> <app-datepicker-dialog id=untilDatepicker with-backdrop=\"\" date={{_until}} min-date={{_from}} locale=[[locale]] disable-days=[] format=yyyy-mm-dd></app-datepicker-dialog> </template> </dom-module>");

require('./date-range-selection.js');
