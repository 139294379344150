Polymer({
  is: 'order-cancel-alert',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    orderCancelReasonSet: {
      type: Array,
      readOnly: true,
      value: global.App.CONST.ORDER_CANCEL_REASON_SET,
    },
    cancelNote: {
      type: String,
      notify: true,
    },
    selectedCancelReason: {
      type: Object,
      value: global.App.CONST.ORDER_CANCEL_REASON_SET[0],
    },
  },

  _continue() {
    global.App.EVENT.emit(':continue:cancel-request', {
      cancelledOrder: {
        cancelReason: this.selectedCancelReason.value,
        cancelNote: this.cancelNote,
      },
    });
  },

  _close() {
    global.App.EVENT.emit(':continue:cancel-request');
  },
});
