Polymer({
  is: 'auth-view',

  behaviors: [Polymer.NeonAnimatableBehavior, Polymer.NeonAnimationRunnerBehavior, global.App.ElementBase],

  properties: {
    selectedView: {
      type: Object,
      notify: true,
      value: 'login',
    },
  },

  attached() {
    if (app.state.query.failure === 'session' && app.state.query.message) {
      app.showToast(app.state.query.message);
    }
  },

  _loadProfile(event, detail) {
    const user = event.target.value;

    if (!(user._token && user.profile)) {
      return;
    }

    const item = {
      name: user.profile.displayName,
      email: user.profile.emails[0].value,
    };

    if (user.profile.provider === 'facebook') {
      item._facebookToken = user._token;
      item.picture = user.profile.picture || `https://graph.facebook.com/${user.profile.id}/picture`;
    }

    if (user.profile.provider === 'google') {
      item._googleToken = user._token;
      item.picture = user.profile.photos[0].value;
    }

    this.set('item', item);
    this.set('selectedView', 'signup');
  },

  prepareSignupWithGoogle(event, detail) {
    this._googleAuthStatusCallback((response) => {
      const item = { ...this.item, ...(response ? response.currentUser : null) };
      item._googleToken = response.authResponse.access_token;
      this.set('item', item);
    });
  },

  prepareSignupWithFacebook() {
    this._facebookAuthStatusCallback((response) => {
      let item = {
        _facebookToken: response.authResponse.accessToken,
      };
      global.FB.api('/me?fields=email,name,picture{url}', (response) => {
        item = Object.assign(item, this.item, response);
        delete item.id;
        item.picture = item.picture.data ? item.picture.data.url : item.picture;
        this.set('item', item);
      });
    });
  },

  loginWithFacebook(event, detail) {
    this._facebookAuthStatusCallback((response) => {
      global.App.fetch(`/auth/login/facebook/token?${global.App.CONFIG.AUTH_QUERY}`, {
        method: 'POST',
        body: {
          access_token: response.authResponse.accessToken,
        },
      })
        .then((response) => {
          app._login(response);
        })
        .catch((error) => {
          this.issues = error.issues;
          app.showToast(error.message);
          // this._fetchFailureHandler(error);
        });
    });
  },

  loginWithGoogle(event, detail) {
    this._googleAuthStatusCallback((response) => {
      if (!response) {
        return;
      }
      global.App.fetch(`/auth/login/facebook/token?${global.App.CONFIG.AUTH_QUERY}`, {
        method: 'POST',
        body: {
          access_token: response.authResponse.access_token,
        },
      })
        .then((response) => {
          app._login(response);
        })
        .catch((error) => {
          this.issues = error.issues;
          app.showToast(error.message);
        });
    });
  },

  _facebookAuthStatusCallback(callback) {
    if (!global.FB) {
      return;
    }

    global.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        callback(response);
      } else {
        global.FB.login((response) => {
          callback(response);
        });
      }
    });
  },

  _googleAuthStatusCallback(callback) {
    if (!global.GoogleAuth) {
      return;
    }

    function _updateCurrentUser() {
      const currentUser = global.GoogleAuth.currentUser.get();

      if (!currentUser || !currentUser.getAuthResponse().access_token) {
        return null;
      }

      return {
        authResponse: currentUser.getAuthResponse(),
        currentUser: {
          name: currentUser.getBasicProfile().getName(),
          picture: currentUser.getBasicProfile().getImageUrl(),
          email: currentUser.getBasicProfile().getEmail(),
        },
      };
    }

    // Listen for sign-in state changes.
    global.GoogleAuth.isSignedIn.listen((response) => {
      callback(_updateCurrentUser());
    });

    // // Listen for changes to current user.
    // global.GoogleAuth.currentUser.listen((response) => {
    //   callback(_updateCurrentUser());
    // });

    if (global.GoogleAuth.isSignedIn.get() === false) {
      global.GoogleAuth.signIn();
    } else {
      callback(_updateCurrentUser());
    }
  },

  _goSignup(event) {
    this.set('selectedView', 'signup');
  },

  _goLogin(event) {
    this.set('selectedView', 'login');
  },

  _doLogin() {
    const form = this.selectedView === 'login' ? '#loginForm' : '#signupForm';

    this.$$(form).do();
  },

  _goAbout() {
    this.$.about.open();
  },

  _goPlayVideo(event, detail) {
    event.stopPropagation();
    this.$.howToVideo.open();
  },

  _closeDialog(event, detail) {
    event.stopPropagation();
    this.$.howToVideo.close();
  },

  _goResetPassword(event, detail) {
    event.stopPropagation();
    app.$.state.goTo('password.reset-request');
  },

  _goResetEmail(event, detail) {
    event.stopPropagation();
    app.$.state.goTo('account.reset-request');
  },

  _goToFacebook(event, detail) {
    window.open('https://go.etaxi.tn/facebook');
  },

  _goToTwitter(event, detail) {
    window.open('https://go.etaxi.tn/twitter');
  },
});
