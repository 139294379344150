const FILTER_ORDER_FOLLOWUP_INITIAL = {
  type: global.App.CONST.ORDER_TYPE.IMMEDIATE,
};

Polymer({
  is: 'order-list-view',

  _entity: 'order',

  behaviors: [global.App.ListViewBase],

  properties: {
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
    filterByValue: {
      type: Object,
      notify: true,
      value: FILTER_ORDER_FOLLOWUP_INITIAL,
    },
  },

  observers: ['_loadFetchedItems(dateRange.*)'],

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });

    global.App.EVENT.on('view:refresh', () => {
      this._loadFetchedItems();
    });
  },

  _loadFetchedItems(dateRange) {
    dateRange = Object.assign(this.dateRange, dateRange.base);
    const filterBy = { ...this.filterByValue, ...dateRange };
    this._fetchItems('/client/order', {
      filterBy,
      sortedBy: 'initiatedAt DESC',
    });
  },
});
