global.App.FormElementBehavior = global.App.utils.merge({}, global.App.ElementBase, {
  properties: {
    data: {
      type: Object,
    },
    item: {
      type: Object,
      notify: true,
    },
    issues: {
      type: Object,
      notify: true,
      value() {
        return {};
      },
    },
  },

  do() {
    this.$.form.submit();
  },

  submit() {
    this.$.form.submit();
  },
});
