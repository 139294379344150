Polymer({
  is: 'driver-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
  },

  _computedTaxiReview(reviewScore) {
    return reviewScore ? `${reviewScore} / 5` : '? / 5';
  },
});
