Polymer({
  is: 'order-detail-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    today: {
      type: String,
      notify: true,
      value() {
        return global.App.moment()
          .format('dddd, DD MMM')
          .toUpperCase();
      },
    },
    time: {
      type: String,
      notify: true,
      value() {
        return global.App.moment().format('HH:mm');
      },
    },
  },

  _computeOrderEstimate(order) {
    const { cost } = global.App.EstimateUtils.calculateOrderEstimate(order);
    return this.$currency(cost, '');
  },
});
