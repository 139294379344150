
require('../iron-flex-layout/iron-flex-layout.html');

require('../paper-styles/color.html');

require('../paper-styles/default-theme.html');

require('../paper-styles/typography.html');

const RegisterHtmlTemplate = require('polymer-webpack-loader/register-html-template');

RegisterHtmlTemplate.register("<dom-module id=paper-item-shared-styles> <template> <style>.paper-item,:host{display:block;position:relative;min-height:var(--paper-item-min-height,48px);padding:0 16px}.paper-item{@apply(--paper-font-subhead);border:none;outline:0;background:#fff;width:100%;text-align:left}.paper-item[hidden],:host([hidden]){display:none!important}.paper-item.iron-selected,:host(.iron-selected){font-weight:var(--paper-item-selected-weight,bold);@apply(--paper-item-selected);}.paper-item[disabled],:host([disabled]){color:var(--paper-item-disabled-color,--disabled-text-color);@apply(--paper-item-disabled);}.paper-item:focus,:host(:focus){position:relative;outline:0;@apply(--paper-item-focused);}.paper-item:focus:before,:host(:focus):before{@apply(--layout-fit);background:currentColor;content:'';opacity:var(--dark-divider-opacity);pointer-events:none;@apply(--paper-item-focused-before);}</style> </template> </dom-module>");
