
require('./auth/about-view.html');

require('./auth/auth-view.html');

require('./auth/password-reset-request-view.html');

require('./auth/reset-password-view.html');

require('./auth/account-reset-request-view.html');

require('./auth/reset-email-view.html');

require('./auth/email-verification-view.html');

require('./auth/phone-verification-view.html');

require('./home/map-view.html');

require('./order/order-list-view.html');

require('./order/order-detail-view.html');

require('./order/history-list-view.html');

require('./booking/booking-list-view.html');

require('./booking/booking-detail-view.html');

require('./booking/booking-upcoming-list-view.html');

require('./account/profile-view.html');

require('./account/profile-avatar-view.html');

require('./account/account-reset-password-view.html');

require('./help/howto-view.html');

require('./help/pricing-view.html');

require('./help/location-view.html');
