function RotateIcon(options) {
  this.options = options || {};
  this.image = options.image || new Image();
  this.image.src = this.image.src || this.options.url;
  this.options.width = this.options.width || this.image.width || 32;
  this.options.height = this.options.height || this.image.height || 32;
  const canvas = document.createElement('canvas');
  canvas.width = this.options.width;
  canvas.height = this.options.height;
  this.context = canvas.getContext('2d');
  this.canvas = canvas;
}

RotateIcon.makeIcon = function (url) {
  return new RotateIcon({
    url,
  });
};

RotateIcon.prototype.setRotation = function (options) {
  if (typeof options !== 'object') {
    options = {
      deg: options,
    };
  }

  const canvas = this.context;
  const angle = options.deg ? (options.deg * Math.PI) / 180 : options.rad;
  const centerX = this.options.width / 2;
  const centerY = this.options.height / 2;

  canvas.clearRect(0, 0, this.options.width, this.options.height);
  canvas.save();
  canvas.translate(centerX, centerY);
  canvas.rotate(angle);
  canvas.translate(-centerX, -centerY);
  canvas.drawImage(this.image, 0, 0);
  canvas.restore();
  return this;
};

RotateIcon.prototype.getUrl = function () {
  return this.canvas.toDataURL('image/png');
};

const MARKER_CACHE = (global.App.MARKER_CACHE = {});

MARKER_CACHE.preload = function (markerSet) {
  markerSet.forEach((icon) => {
    MARKER_CACHE[icon] = {};

    // preload taxi image
    const image = new Image();

    // predefine rotated marker images
    image.addEventListener('load', () => {
      const iconObject = RotateIcon.makeIcon(icon);

      const marker = {
        path: iconObject.getUrl(),
        width: iconObject.options.width,
        height: iconObject.options.height,
      };

      MARKER_CACHE[icon] = marker;
    });

    image.src = icon;
  });
};

MARKER_CACHE.preloadRotated = function (markerSet) {
  markerSet.forEach((icon) => {
    MARKER_CACHE[icon] = {};

    // preload taxi image
    const image = new Image();

    // predefine rotated marker images
    image.addEventListener('load', () => {
      let rotation;
      let iconObject;
      let marker;

      for (rotation = 0; rotation <= 360; rotation++) {
        iconObject = RotateIcon.makeIcon(icon).setRotation(rotation);

        marker = {
          path: iconObject.getUrl(),
          width: iconObject.options.width,
          height: iconObject.options.height,
        };

        MARKER_CACHE[icon][rotation] = marker;
      }
    });

    image.src = icon;
  });
};

MARKER_CACHE.preload(['/images/marker-request.png', '/images/marker-end.png']);

MARKER_CACHE.preloadRotated([
  '/images/taxi.png',
  '/images/taxi-off.png',
  '/images/taxi-idle.png',
  '/images/taxi-busy.png',
  '/images/taxi-order.png',
]);
