const VERIFY_PHONE_STATE_SET = {
  'send-token': {
    title: 'Saisir votre téléphone',
  },
  'verify-phone': {
    title: 'Vérifier votre téléphone',
    class: '-right-middle',
  },
  'finish-verification': {
    title: 'Confirmer!',
    class: '-right-end',
  },
};

Polymer({
  is: 'phone-verification-view',

  behaviors: [global.App.ElementBase],

  properties: {
    stateSet: {
      type: Array,
      value() {
        return VERIFY_PHONE_STATE_SET;
      },
    },
    viewTitle: {
      type: Array,
      notify: true,
    },
    user: {
      type: Object,
      value() {
        return app.user;
      },
    },
    selectedState: {
      type: Boolean,
      notify: true,
      value() {
        return 'send-token';
        // return (app.user && app.user.phoneVerified) ? 'finish-verification' : 'send-token';
      },
    },
  },

  observers: ['_computedProgressStepClass(selectedState)'],

  ready() {
    app.progress = this.$.progressBar;
  },

  _computedTitle(states, state) {
    const value = states[state];
    return value.title || '';
  },

  _computedProgressStepClass(selectedState) {
    if (selectedState === 'send-token') {
      // this.$$('#sendToken').disabled = false;
      this.$.progressBar.value = 0;
    }

    if (selectedState === 'verify-phone') {
      this.$.progressBar.value = this.$.progressBar.max / 2;
    }

    if (selectedState === 'finish-verification') {
      this.$.progressBar.value = this.$.progressBar.max;
    }
  },

  _computedStep2Class(state) {
    return !(state === 'send-token') ? '-active' : '-inactive';
  },

  _computedStep3Class(state) {
    return state === 'finish-verification' ? '-active' : '-inactive';
  },

  _goSendToken() {
    this.set('selectedState', 'send-token');
  },

  _sendToken() {
    this.$$('#sendTokenButton').disabled = true;
    const body = this.$$('#sendVerificationTokenForm').serialize();
    global.App.fetch('/auth/phone', {
      method: 'POST',
      body,
    })
      .then((response) => {
        const user = Object.assign(app.user, response.value);
        this.set('user', user);

        global.App.EVENT.emit('view:refresh');

        const selectedState = user.phoneVerified ? 'finish-verification' : 'verify-phone';
        this.$$('#sendTokenButton').disabled = false;
        setTimeout(() => {
          this.set('selectedState', selectedState);
        }, 10);
      })
      .catch((error) => {
        this.$$('#sendTokenButton').disabled = false;
        this._fetchFailureHandler(error);
      });
  },

  _verifyPhone() {
    this.$$('#verifyTokenButton').disabled = true;
    const body = this.$$('#VerifyTokenForm').serialize();
    global.App.fetch('/auth/verify/phone', {
      method: 'POST',
      body,
    })
      .then((response) => {
        const user = Object.assign(app.user, response.value);
        this.set('user', user);

        global.App.EVENT.emit('view:refresh');

        this.$$('#verifyTokenButton').disabled = false;
        setTimeout(() => {
          this.set('selectedState', 'finish-verification');
        }, 10);
      })
      .catch((error) => {
        this.$$('#verifyTokenButton').disabled = false;
        this._fetchFailureHandler(error);
      });
  },

  _goToLogin() {
    app.$.state.goTo('login');
  },

  _goToMap() {
    global.App.EVENT.emit('view:refresh', true);
  },
});
