Polymer({
  is: 'comment-input',

  properties: {
    /**
     * placeholder for the comment-input
     *
     * @attribute label
     * @type String
     */
    label: {
      type: String,
      notify: true,
    },
    /**
     * message for the comment-input the comment-input
     *
     * @attribute message
     * @type String
     */
    message: {
      type: String,
      notify: true,
    },
  },
});
