// eslint-disable-next-line global-require
const CONST = { ...require('etaxi-lib/src/common/const') };

CONST.PAGINATE_INITIAL = {
  pageSize: 50,
  page: 1,
  pages: '?',
  count: '?',
};

CONST.ROUTE_COLOR = '#137FB4'; // #00B3FD #137FB4
CONST.TAXI_ROUTE_COLOR = '#FAB216'; // #FFD008 #FAB216
CONST.APPROXIMATIVE_LOCATION_FILL_COLOR = '#666666';
CONST.APPROXIMATIVE_LOCATION_STROKE_COLOR = '#333333';

CONST.ORDER_STATE_SET = {
  initiated: {
    label: 'Course demandée',
    value: 'initiated',
    class: '-grey',
  },
  pending: {
    label: "En attente d'une réponse",
    value: 'pending',
    class: '-orange',
  },
  started: {
    label: 'Vers le client',
    value: 'started',
    class: '-light-green',
  },
  onhold: {
    label: 'En attende de client',
    value: 'onhold',
    class: '-yellow',
  },
  ongoing: {
    label: 'En cours',
    value: 'ongoing',
    class: '-green',
  },
  finished: {
    label: 'Arrivé à destination',
    value: 'finished',
    class: '-dark-blue',
  },
  done: {
    label: 'Payé',
    value: 'done',
    class: '-dark-green',
  },
  cancelled: {
    label: 'Annulée',
    value: 'cancelled',
    class: '-red',
  },
  timedout: {
    label: 'Ratéé',
    value: 'timedout',
    class: '-red',
  },
};

CONST.BOOKING_STATE_SET = {
  initiated: {
    label: 'Course demandée',
    value: 'initiated',
    class: '-grey',
  },
  pending: {
    label: "En attente d'une réponse",
    value: 'pending',
    class: '-orange',
  },
  attributed: {
    label: 'Traitée',
    value: 'attributed',
    class: '-light-green',
  },
  cancelled: {
    label: 'Annulée',
    value: 'cancelled',
    class: '-red',
  },
};

CONST.SELECTED_REQUESTED_ORDER_STATES = {
  initiated: {
    value: 'order-request',
  },
  pending: {
    value: 'order-pending',
  },
  started: {
    value: 'order-ongoing',
  },
  ongoing: {
    value: 'order-ongoing',
  },
  onhold: {
    value: 'order-ongoing',
  },
  finished: {
    value: 'order-review',
  },
  timedout: {
    value: 'order-timedout',
  },
  cancelled: {
    value: 'order-cancelled',
  },
};

CONST.ORDER_CANCEL_REASON_SET = [
  {
    label: 'Plus besoin de service',
    value: 'service-not-needed',
  },
  {
    label: 'Durée de service',
    value: 'service-duration',
  },
  {
    label: 'Les frais de service',
    value: 'service-cost',
  },
  {
    label: 'Autre',
    value: 'other',
  },
];

module.exports = Object.freeze(CONST);
