Polymer({
  is: 'empty-card',
  properties: {
    icon: {
      type: String,
      icon: 'database',
    },
    message: {
      type: String,
      value: 'Aucun enregistrements!',
    },
  },
});
